import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CircularProgress, Pagination, Box, Checkbox, TableSortLabel } from '@mui/material';
import { getProducts } from '../databaseManager.js';
import AdaptiveImage from './adaptiveImage.js';
import { formattedDate } from './sharedUtils.js';

export default function ProductList() {
  const navigate = useNavigate();
  const params = useParams();
  const customer = params['customer'];
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [lastSnap, setLastSnap] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [sortOrder, setSortOrder] = useState('asc');

  // Fetch products only when page or customer changes
  useEffect(() => {
    setLoading(true);
    getProducts(pageSize, lastSnap, customer, '', (productList, snap) => {
      setData(productList);
      setLastSnap(snap);
      setHasMore(productList.length > 0 || snap != null);
      setLoading(false);
    }, false);
  }, [page, customer]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleSortChange = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);

    setData(prevData =>
      [...prevData].sort((a, b) =>
        newSortOrder === 'asc'
          ? new Date(a.created) - new Date(b.created)
          : new Date(b.created) - new Date(a.created)
      )
    );
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Product List
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Image</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>
                  {/* <TableSortLabel
                    active
                    direction={sortOrder}
                    onClick={handleSortChange}
                  > */}
                    Date Created
                  {/* </TableSortLabel> */}
                </TableCell>
                <TableCell>On eBay</TableCell>
                <TableCell>On Shopify</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((product, index) => (
                <TableRow
                  key={`${product.created}-${index}`}
                  hover
                  sx={{ cursor: 'pointer' }}
                  onClick={() => navigate(product.productUri)} // Navigates to product URL or new component
                >
                  <TableCell sx={{ height: 100, width: 100 }}>
                    <AdaptiveImage src={product.mainImage} style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
                  </TableCell>
                  <TableCell sx={{ height: 100 }}>
                    {product.title}
                  </TableCell>
                  <TableCell sx={{ height: 100 }}>{product.price}</TableCell>
                  <TableCell>
                    {formattedDate(product.created)}  
                  </TableCell>
                  <TableCell>
                    <Checkbox checked={product.onEbay} disabled />
                  </TableCell>
                  <TableCell>
                    <Checkbox checked={product.onShopify} disabled />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {hasMore && (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <Pagination
            count={Math.ceil(data.length / pageSize)} // Update based on total items
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      )}
    </Box>
  );
}