import React, { useState, useEffect } from 'react';
import { Container, Typography, Select, MenuItem, Grid, Card, CardContent, Paper, Button, CircularProgress } from '@mui/material';
import { get } from './api';
import { useAuth } from './authContext';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { formattedDate } from './sharedUtils';


const UserManagement = () => {
    const { customer, user, logout } = useAuth();
    const [loading, setLoading] = useState(true); // Loading state for customer data
    const navigate = useNavigate();
    const [users, setUsers] = useState([])
    const db = getFirestore();


    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const goToPlanPage = () => {
        navigate('/plans');
    };

    // Gen

    useEffect(() => {
        if (customer && customer.created) {

            const userData = [];
            const fetchUserData = async () => {
            for (const user of customer.users) {
                const userId = user.userId; // Extract userId from the customer.users array
                try {
                  const userDocRef = doc(db, 'users', userId); // Reference to the Firestore document
                  const userDocSnapshot = await getDoc(userDocRef);
        
                  if (userDocSnapshot.exists()) {
                    userData.push(userDocSnapshot.data()); // Push the document data into the array
                  } else {
                    console.log(`No such document for userId: ${userId}`);
                  }
                } catch (error) {
                  console.error(`Error fetching user document for userId: ${userId}`, error);
                }
              }
              setLoading(false); // Data is fully loaded
              setUsers(userData)
            console.log(customer.users)
            } 
            fetchUserData()
        }
    }, [customer]);


    return (
        <>
            {loading ? (
                <Container
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <CircularProgress />
                </Container>
            ) : (
                <Container>
          <Grid container spacing={3}>
            {users.map((user, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card elevation={3}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {user.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Email: {user.email}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Instagram: {user.instagram}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Phone: {user.phoneNumber}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Referred By: {user.referredBy}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Created: {formattedDate(user.created)}
                    </Typography>
                    {/* <Typography variant="body2" color="textSecondary">
                      Last Online: {user.lastOnline}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Sign In Method: {user.signInMethod}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      User ID: {user.userId}
                    </Typography> */}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
            )}
            </>
    );
};

export default UserManagement;