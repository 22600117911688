import React from 'react';
import { auth, db  } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, OAuthProvider } from 'firebase/auth';
import { useLocation } from 'react-router-dom';

const SignIn = () => {
  const location = useLocation();
  
  // Get the last part of the URL
  const lastPath = location.pathname.split('/').pop();

  // Define a pattern to match (adjust this pattern based on your needs)
  // const pattern = /^Np9mJdL4AFTsIfL5KjDPloyEUzB3$/; 
  const pattern = /^Np9mJdL4AFTsIfL5KjDPloyEUzB3$/; 

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).then((result) => {
      window.parent.postMessage({ action: 'closePopup' }, '*');
    }).catch((error) => {
      console.error(error);
    });
  };

  const signInWithApple = () => {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    signInWithPopup(auth, provider).then((result) => {
      window.parent.postMessage({ action: 'closePopup' }, '*');
    }).catch((error) => {
      console.error(error);
    });
  };

  return (
    <div className='center-text'> 
       {/* Conditionally display the welcome message based on the URL pattern */}
       {pattern.test(lastPath) && (
        <b>
          Welcome to DETOURE the easiest / fastest way to sell your closet on the internet. <br/>
          We use AI to make selling simple…no more listing for hours<br />
          Sign in to start selling!!
        </b>
      )}
      <button className="signin-button" onClick={signInWithGoogle}>
      <img src="images/google-signin.png" alt="Google Sign in"/>
      </button>
      <button className="signin-button"  onClick={signInWithApple}>
      <img src="images/apple-signin.png" alt="Apple Sign in"/>
        </button>
    </div>
  );
};

export default SignIn;