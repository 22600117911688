import React, { useEffect, useState } from 'react';
import { db, appShopifyHandoff, storage } from '../firebase';
import { doc, getDoc, setDoc, collection, onSnapshot } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuth } from './authContext';
import { Button, CircularProgress, TextField, MenuItem, Select, InputLabel, FormControl, Box, Typography } from '@mui/material';
import { conditionValues, conditionToValueMap, constants, getDropdownOptions, translateDropdownOptions } from './productEditConstants';
import { customerHasShopify, customerHasEbay } from './sharedUtils';
import { Alert } from '@mui/material';
import { postProductToChannel } from './api';

const inflect = require('inflection');

const ProductEditGeneric = ({ customerId, productId, updateState, uploaderStyle, groupIndex }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({});
  const [imageUrls, setImageUrls] = useState([]);
  const [formatPrefs, setFormatPrefs] = useState({});
  const [customFields, setCustomFields] = useState({});
  const [missingRequiredFields, setMissingRequiredFields] = useState(false);
  const [dynamicOptions, setDynamicOptions] = useState({})
  const { customer, shopikUser } = useAuth(); // Assuming 'customer' contains user data
  const [shopifyUrl, setShopifyUrl] = useState(null);
  const [ebayUrl, setEbayUrl] = useState(null);
  const [error, setError] = useState(null);

  // Function to handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    formData[name] = value;
    let title = updateTitle(formData);
    let description = updateDescription(formData);
    setFormData({
      ...formData,
      ['Title']: title,
      ['Description']: description,
      [name]: value,
    });
  };

const getKeyByValue = (obj, targetValue) => {
  return Object.keys(obj).find(key => obj[key] === targetValue);
};

  // Function to handle dropdown changes
  const handleDropdownChange = (e) => {
    var { name, value } = e.target;
    if (name === 'Condition') {
      value = getKeyByValue(conditionToValueMap, value)
    }
    setFormData((prevFormData) => {
      let updatedFormData = { ...prevFormData, [name]: value };

      // if (name === 'Category') {
      //   // Remove any existing fields that shouldn't be in formData based on category change
      //   Object.keys(categoryToFieldsMap).forEach((category) => {
      //     categoryToFieldsMap[category].forEach((field) => {
      //       if (updatedFormData.hasOwnProperty(field) && category !== value) {
      //         delete updatedFormData[field];
      //       }
      //     });
      //   });

      //   // Add relevant fields to formData based on the selected category
      //   const fieldsForCategory = categoryToFieldsMap[value] || [];
      //   fieldsForCategory.forEach((field) => {
      //     if (!updatedFormData.hasOwnProperty(field)) {
      //       updatedFormData[field] = '';
      //     }
      //   });
      // }

      return updatedFormData;
    });
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!areDropdownsFilled()) {
      setMissingRequiredFields(true);
      return;
    }

    const dbUserRef = collection(db, 'customers');
    const userRef = doc(dbUserRef, customerId);
    const productsRef = collection(userRef, 'products');
    const pRef = doc(productsRef, productId);
    const productData = (await getDoc(pRef)).data();
    var existingData = productData.consolidatedData;

    for (const key in formData) {
      if (key  === 'Category' && formData[key] !== existingData[key]) {
        //TODO retry and exit
      }
      existingData[key] = formData[key];
    }
    await setDoc(pRef, { consolidatedData: existingData }, { merge: true });
  };

  const areDropdownsFilled = () => {
    return true;
    // if (uploaderStyle !== 1) {
    //   return true;
    // }
    // return Object.keys(dropdownFields).every((key) => {
    //   const value = formData[key];
    //   return value !== 'Select an option' && value !== 'Not Available' && value !== '';
    // });
  };
  
  function buildSpecs(specs) {
    const addAfter = [];
    const newArray = [];
    const dropdownOptions = {};
    const map = specs['consolidatedData']
    newArray.push(['Title', map['Title']])
    newArray.push(['Description', map['Description']])
    if (map.Price) {
      newArray.push(['Price', map['Price']])
    }
    if (map.Category) {
      newArray.push(['Category', map['Category']])
    } 
    if (map.Condition) {
      newArray.push(['Condition', map['Condition']])
    }
    // newArray.push(['eBay Category', map['Category']])
    for (const item in map) {
      // if (item === 'Category') {
      //   continue
      // }
      if (getDropdownOptions(item, specs).length > 0) {
        dropdownOptions[item] = getDropdownOptions(item, specs)
      }
      var value = map[item]
      if (!constants.EXCLUDE_FROM_PRODUCT_TERMS.includes(value.toLowerCase())) {
        newArray.push([item, value])
      } else {
        addAfter.push(item);
      }
    }
  
    for (const item of addAfter) {
      var value = map[item]
      newArray.push([item, value])      
    }
    const specDict = {};
    for (const item of newArray) {
      specDict[item[0]] = item[1]
    }
    setDynamicOptions(dropdownOptions)
    return specDict;
  }

  function updateTitle(consolidatedData) {
    if (formatPrefs.hasOwnProperty('titleFormat')) {
      let baseTitle = '';
      formatPrefs.titleFormat.split(',').forEach((field) => {
        if (consolidatedData.hasOwnProperty(field)) {
          if (consolidatedData[field] !== 'Unknown') {
            baseTitle += consolidatedData[field].replace(/,/g, ' and ') + ' ';
          }
        } else if (field.includes('literal')) {
          baseTitle += field.replace('literal', '') + ' ';
        }
      });
      baseTitle = baseTitle.trim();
      baseTitle = singularizeLastWord(baseTitle);
      if (baseTitle === '') {
        baseTitle = consolidatedData['Title'];
      }
      return baseTitle;
    }
    return consolidatedData['Title'];
  }

  function updateDescription(consolidatedData) {
    if (formatPrefs.hasOwnProperty('descriptionFormat')) {
      let baseDescription = '';
      formatPrefs.descriptionFormat.split(',').forEach((field) => {
        if (consolidatedData.hasOwnProperty(field)) {
          baseDescription += `${field}: ${consolidatedData[field]}<br>`;
        } else if (customFields.hasOwnProperty(field)) {
          baseDescription += `${field}: ${customFields[field]}<br>`;
        }
      });
      return baseDescription;
    }
    return consolidatedData['Description'];
  }

  function singularizeLastWord(title) {
    const words = title.split(' ');
    const word = words[words.length - 1];
    const singularWord = inflect.singularize(word);
    words[words.length - 1] = singularWord;
    return words.join(' ');
  }

  const toggleImageAtIndex = (index) => {
    setImageUrls((prevUrls) =>
      prevUrls.map((url, i) =>
        i === index
          ? url.includes('noBackground')
            ? url.replace('noBackground', 'original') // Toggle to original
            : url.replace('original', 'noBackground') // Toggle to noBackground
          : url
      )
    );
  };

  const handleShopifyPost = async (e) => {  
    setIsSubmitting(true)
    handleSubmit(e)

    try {
      const response = await postProductToChannel(customer.apiKey, productId, 'shopify', {});
      setShopifyUrl(response.uri)
      setError(null); // Clear error on success
    } catch (err) {
        setError(err.message || 'Failed to post product to Shopify');
    }
    setIsSubmitting(false)
  }

  const handleEbayPost = async (e) => {
    setIsSubmitting(true)
    handleSubmit(e)

    try {
      const response = await postProductToChannel(customer.apiKey, productId, 'ebay', {});
      setEbayUrl(process.env.REACT_APP_EBAY_PRODUCT_URL + '/' + response.listingId)
      setError(null); // Clear error on success
    } catch (err) {
        setError(err.message || 'Failed to post product to eBay');
    }
    setIsSubmitting(false)
  }

  useEffect(() => {
    const custColRef = collection(db, "customers");
    const custDocRef = doc(custColRef, customerId);
    const prodColRef = collection(custDocRef, "products");
    const prodDocRef = doc(prodColRef, productId);
    
    setupFormatting(customerId, productId);
    
    const unsub = onSnapshot(prodDocRef, async (productDoc) => {
      const data =  productDoc.data();
      if (data['consolidatedData']) {
        const specDict = buildSpecs(data);
        setFormData(specDict);
        setIsLoading(false);
        if (data.shopifyArray) {
          setShopifyUrl(data.shopifyArray[0].uri)
        }
        if (data.eBayArray) {
          setEbayUrl(process.env.REACT_APP_EBAY_PRODUCT_URL + '/' + data.eBayArray[0].listingId)
        }
        unsub(); // Unsubscribe after loading specs
      } 
      if (data['mainImages']) {
        setImageUrls(data.mainImages.filter(image => !image.isHidden).map(image => image.urlString));
      }
    });
  
    // Cleanup function to unsubscribe when component unmounts or customerId or productId change
    return () => {
      unsub();
    };
  }, [customerId, productId]); // Add customerId and productId to dependency array

  const setupFormatting = async (customerId, productId) => {
    const custColRef = collection(db, "customers");
    const custDocRef = doc(custColRef, customerId);
    const prodColRef = collection(custDocRef, "products");
    const prodDocRef = doc(prodColRef, productId);
    const custDict = (await getDoc(custDocRef)).data();
    const productDict = (await getDoc(prodDocRef)).data();
    
    let formatData = {};
    let customFields = {};

    if (custDict.hasOwnProperty('outputPrefs')) {
        formatData = custDict.outputPrefs;
        setFormatPrefs(formatData);
        if (formatData.hasOwnProperty('customUserInfo') && productDict.hasOwnProperty('uploadedBy')) {
            let userId = productDict.uploadedBy.replace('localDebugDelete', '');
            const userColRef = collection(db, "users");
            const userDocRef = doc(userColRef, userId);
            const userDict = (await getDoc(userDocRef)).data();

            for (let field in formatData.customUserInfo) {
                let valueString = formatData.customUserInfo[field];
                let baseValue = '';

                valueString.split(',').forEach(innerField => {
                    if (userDict.hasOwnProperty(innerField)) {
                        baseValue += userDict[innerField] + ' ';
                    }
                });

                baseValue = baseValue.trim();
                customFields[field] = baseValue;
            }
        }
        setCustomFields(customFields);
    }
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
      {imageUrls.length > 0 && (
        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
          <Box display="flex" flexDirection="column" alignItems="center">
            {imageUrls.map((url, index) => (
              <Box key={index} display="flex" flexDirection="column" alignItems="center" mb={3}>
                <img
                  id={`processed-image-${index}`}
                  src={url}
                  alt={`Product Image ${index}`}
                  width={300}
                  style={{ background: 'white', borderRadius: '2px', border: '2px solid gray' }}
                />
                {uploaderStyle === 1 && (
                  <Typography>Please keep the background removed for product images and re-add it for any photos of you in the product.</Typography>
                )}
                <Button
                  onClick={() => toggleImageAtIndex(index)}
                  sx={{ marginTop: 1, padding: '5px 10px',  backgroundColor: '#4751ed', '&:hover': { backgroundColor: '#0f0721' }}}
                  variant="contained"
                >
                  Background ON/OFF
                </Button>
              </Box>
            ))}
          </Box>

          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            {Object.entries(formData).map(([key, value]) => {
              const isCustomKey = false;// key === 'Title';
              const isDescription = false;//key === 'Description';
              const isDropdown = dynamicOptions.hasOwnProperty(key);

              return (
                <React.Fragment key={key}>
                  {!isDescription && (
                    <FormControl fullWidth margin="normal">
                      {/* <InputLabel id={`${key}-label`}>{key}</InputLabel> */}
                      {isDropdown ? (
                        <Select
                          labelId={`${key}-label`}
                          id={key}
                          name={key}
                          value={translateDropdownOptions(key, value)}
                          onChange={handleDropdownChange}
                          sx={{  width: '80%',
                            paddingLeft: '20px',  // Add padding to prevent overflow on the left
                            textAlign: 'left',  // Center text within the select box
                            textOverflow: 'ellipsis', // Handle overflow if text is too long
                            overflow: 'hidden',
                            whiteSpace: 'nowrap'}}
                        >
                          {dynamicOptions[key].map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <TextField
                          id={key}
                          name={key}
                          label={key}
                          value={value}
                          onChange={handleChange}
                          multiline
                          rows={0}  // Adjust the number of rows to your needs
                          sx={{ width: '80%' }}
                          InputProps={{
                            readOnly: isCustomKey,
                            sx: {
                              paddingLeft: '10px',  // Add 10px of padding to the left
                            },
                          }}
                        />
                      )}
                    </FormControl>
                  )}
                </React.Fragment>
              );
            })}

            {Object.keys(formData).length !== 0 && (
              <>
                {isSubmitting ? (
                  <>
                  {error ? <Alert severity="error">{error}</Alert> : <CircularProgress />}
                  </>
                ) : (
                  <>
                  <Box sx={{ display: 'flex', gap: 2, flexDirection: 'row', justifyContent: 'center', marginTop: 2 }}>
                  <Button
                    type="submit"
                    sx={{ padding: '10px 20px', backgroundColor: '#4751ed', '&:hover': { backgroundColor: '#0f0721' } }}
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    {uploaderStyle === 1 ? 'Submit' : 'Save'}
                  </Button>
                  {customerHasShopify(customer) && (shopifyUrl ? (
                   <Button
                   onClick={() => window.open(shopifyUrl, '_blank')}
                   sx={{ padding: '10px 20px', backgroundColor: '#4751ed', '&:hover': { backgroundColor: '#0f0721' } }}
                   variant="contained"
                   disabled={isSubmitting}
                  >
                  View Shopify Listing
                  </Button>
                  ) : (
                    <Button
                        onClick={handleShopifyPost}
                        sx={{ padding: '10px 20px', backgroundColor: '#4751ed', '&:hover': { backgroundColor: '#0f0721' } }}
                        variant="contained"
                        disabled={isSubmitting}
                    >
                    Post to Shopify
                    </Button>
                  )
                  )}
                 {customerHasEbay(customer) && (ebayUrl ? (
                   <Button
                   onClick={() => window.open(ebayUrl, '_blank')}
                   sx={{ padding: '10px 20px', backgroundColor: '#4751ed', '&:hover': { backgroundColor: '#0f0721' } }}
                   variant="contained"
                   disabled={isSubmitting}
                  >
                  View eBay Listing
                  </Button>
                  ) : (
                    <Button
                        onClick={handleEbayPost}
                        sx={{ padding: '10px 20px', backgroundColor: '#4751ed', '&:hover': { backgroundColor: '#0f0721' } }}
                        variant="contained"
                        disabled={isSubmitting}
                    >
                        Post to eBay
                    </Button>
                  )
                  )}
                  </Box>
                  </>
                )}
              </>
            )}
          </form>
        </Box>
      )}
      {isLoading && (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
          <CircularProgress />
          {uploaderStyle === 1 ? (
            <Typography>This might take a few minutes — sit back and get comfy. We’ll be ready in no time!</Typography>
          ) : (
            <>
              {imageUrls.length === 0 && <Typography>Analyzing and enhancing images....</Typography>}
              {imageUrls.length > 0 && <Typography>Determining item specifications and price....</Typography>}
              {!formData && <Typography>Getting more detailed specifications and verifying....</Typography>}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ProductEditGeneric;