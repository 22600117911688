export default function Terms() {
  return (
    <div className="Terms">
     <div className="terms-section">
  <div className="section-padding hero">
    <div className="container-default">
      <div className="terms-container">
        <div className="terms-title-wrp">
          <h1>Terms &amp; conditions</h1>
        </div>
        <div className="terms-wrp">
          <div className="terms-rich w-richtext">
            <h2>Website Terms of Use</h2>
            <p>Current as of Oct, 9, 2023. </p>
            <p>‍</p>
            <p>
              Welcome to www.shopik.io (together with any related websites, the
              “Site”). The Site is owned and operated by Shopik, Inc.
              (“Shopik”). Please read these Terms of Use (“Terms”) carefully
              before using the Site. By accessing or using the Site or any of
              the content on the Site you agree to be legally bound by these
              Terms. If you do not accept these Terms, do not use the Site or
              any of its Content (defined below).
            </p>
            <p>‍</p>
            <p>
              You represent to Shopik that you are lawfully able to enter into
              contracts (e.g., you are not a minor). If you are entering into
              these Terms for an entity, such as the company you work for, you
              represent that you have authority to bind that entity and you
              agree that “you” as used in these Terms includes both you
              personally and the entity you represent. You and Shopik are
              collectively referred to as the “Parties” and each is a “Party”.
            </p>
            <p>‍</p>
            <p>Master Subscription Agreement</p>
            <p>
              ‍<br />
              These Terms do not govern the use of the hosted application and
              infrastructure monitoring service as well as other services (the
              “Service”) operated by Shopik. If you are accessing or using the
              Service – even if you are doing so through a free trial – then you
              are subject to Shopik's Master Subscription Agreement or such
              other written contract as may be separately agreed and signed
              between you and Shopik.
            </p>
            <p>‍</p>
            <p>1. Shopik Content</p>
            <p>
              ‍<br />
              The Site contains HTML, applications, messages, text, files,
              images, photos, video, sounds, profiles, works of authorship, and
              other content (collectively, “Content”) of Shopik or its licensors
              (“Shopik Content”). The Site (including the Shopik Content) is
              protected by copyright, trademark, trade secret, and other laws;
              and as between you and Shopik, Shopik owns and retains all rights
              in the Site and the Shopik Content. Shopik hereby grants to you a
              limited, revocable, non-sublicensable license to access, display,
              and perform the Shopik Content (excluding any computer code)
              solely for your personal, non-commercial use and solely as
              necessary to access and use the Site. Except as expressly
              permitted by Shopik in these Terms or on the Site, you may not
              copy, download, stream, capture, reproduce, duplicate, archive,
              upload, modify, translate, create derivative works based upon,
              publish, broadcast, transmit, retransmit, distribute, perform,
              display, sell, or otherwise use or transfer any Shopik Content.
              You may not, either directly or through the use of any device,
              software, online resource, or other means, remove, alter, bypass,
              avoid, interfere with, or circumvent any copyright, trademark, or
              other proprietary notice on the Shopik Content or any digital
              rights management mechanism, device, or other content protection
              or access control measure associated with the Shopik Content.
            </p>
            <p>‍</p>
            <p>2. Trademarks</p>
            <p>
              ‍<br />
              The trademarks, logos, and service marks (“Marks”) displayed on
              the Site are the property of Shopik or other third parties. You
              are not permitted to use these Marks without the prior written
              consent of Shopik or such third party.
            </p>
            <p>‍</p>
            <p>
              3. Third-Party Services
              <br />‍
            </p>
            <p>
              The Site may make available, or third parties may provide, links
              to other websites, applications, resources, advertisements,
              Content, or other products or services created, hosted, or made
              available by third parties (“Third-Party Services”), and such
              third parties may use other third parties to provide portions of
              the Third-Party Service to you, such as technology, development,
              or payment services. When you access or use a Third-Party Service,
              you are interacting with the applicable third party, not with
              Shopik, and you do so at your own risk. Shopik is not responsible
              for, and makes no warranties, express or implied, as to, the
              Third-Party Services or the providers of such Third-Party Services
              (including without limitation the accuracy or completeness of the
              information provided by such Third-Party Service or the privacy
              practices of any third party). Inclusion of any Third-Party
              Service or a link thereto on the Site does not imply approval or
              endorsement of such Third-Party Service. Shopik is not responsible
              or liable for the Content or practices of any Third-Party Service
              or third party, even if such Third-Party Service links to, or is
              linked by, the Site.
            </p>
            <p>‍</p>
            <p>
              4. Privacy
              <br />‍
            </p>
            <p>
              Please review Shopik's Privacy Policy for the Site (“Privacy
              Policy”), to learn about Shopik's information collection, usage,
              and disclosures practices with respect to information collected by
              Shopik through the Site.
            </p>
            <p>‍</p>
            <p>
              5. Your Content
              <br />‍
            </p>
            <p>
              By posting, displaying, publishing, or making available for
              download or use any Content on the Site (other than personal
              information that is subject to the Privacy Policy), you hereby
              grant Shopik a perpetual, worldwide, nonexclusive, irrevocable,
              royalty-free, sublicensable (through multiple tiers) license to
              perform, display, reproduce, prepare derivative works from,
              distribute, sell, sublicense, transfer, and otherwise use without
              restriction all or any part of such Content.
            </p>
            <p>‍</p>
            <p>6. Acceptable Use</p>
            <p>‍</p>
            <p>
              Your use of the Site is subject to Shopik's Acceptable Use Policy.
              Shopik is not responsible or liable for any user Content or
              conduct on the Site. If you become aware of any misuse of the
              Site, please report such misuse immediately to Shopik at{" "}
              <a href="mailto:hello@shopik.io" target="_new">
                hello@shopik.io
              </a>
              .
            </p>
            <p>‍</p>
            <p>7. Compliance with Laws</p>
            <p>‍</p>
            <p>
              You represent that, in agreeing to, and performing under, these
              Terms, you are not violating, and will not violate, any
              governmental laws, rules, regulations, or orders that are
              applicable to your use of the Site (“Applicable Laws”). Without
              limiting the foregoing, you represent that, in connection with
              your performance under these Terms, you shall: (a) comply with
              Applicable Laws relating to anti-bribery and anti-corruption,
              which may include the US Foreign Corrupt Practices Act of 1977 and
              the UK Bribery Act 2010; (b) comply with Applicable Laws
              administered by the U.S. Commerce Bureau of Industry and Security,
              U.S. Treasury Office of Foreign Assets Control or other
              governmental entity imposing export controls and trade sanctions
              (“Export Laws”), including designating countries, entities, and
              persons (“Sanctions Targets”); and (c) not directly or indirectly
              export, re-export or otherwise deliver any Shopik software,
              content, or services to a Sanctions Target, or broker, finance, or
              otherwise facilitate any transaction in violation of any Export
              Laws. You represent that you are not a Sanctions Target or
              prohibited from receiving Shopik software, content, or services
              pursuant to these Terms under Applicable Laws, including Export
              Laws.
            </p>
            <p>‍</p>
            <p>8. Global Availability</p>
            <p>‍</p>
            <p>
              Shopik controls the Site from its offices. If you use this website
              from other locations, you are responsible for compliance with
              local Applicable Laws. Shopik makes no representation that the
              products and services referenced herein are appropriate, or
              available, worldwide.
            </p>
            <p>‍</p>
            <p>9. Indemnity</p>
            <p>‍</p>
            <p>
              You agree to defend, indemnify, and hold harmless Shopik, its
              affiliates, and their respective employees, contractors, agents,
              officers, and directors from and against any and all claims,
              damages, obligations, losses, liabilities, costs, debt, or
              expenses (including without limitation attorneys' fees) arising
              out of or related to any claim, suit, action, or proceeding by a
              third party arising out of or relating to your use of the Site,
              breach of these Terms (including any Shopik policy referenced in
              these Terms), violation of law, or any Content that you post,
              upload, or cause to interface with the Site, or otherwise
              transfer, process, use, or store in connection with the Site.
            </p>
            <p>‍</p>
            <p>10. Disclaimers</p>
            <p>‍</p>
            <p>
              THE SITE AND ALL CONTENT AND OTHER ITEMS MADE AVAILABLE BY SHOPIK
              ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT
              WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR
              OTHERWISE, INCLUDING ANY IMPLIED WARRANTY OF TITLE,
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
              NON-INFRINGEMENT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
              LAW. SHOPIK ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (i)
              ERRORS, MISTAKES OR INACCURACIES OF DATA OR INFORMATION POSTED,
              DISPLAYED, PUBLISHED OR MADE AVAILABLE FOR DOWNLOAD OR USE ON THE
              SITE, (ii) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
              WHATSOEVER, RESULTING FROM USE OF THE SITE, (iii) ANY INTERRUPTION
              OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, OR (iv) THE
              DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF ANY THIRD PARTY NOT
              UNDER SHOPIK'S CONTROL.
            </p>
            <p>‍</p>
            <p>11. Limitation of Liability</p>
            <p>‍</p>
            <p>
              TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW: (a) IN NO EVENT
              SHALL SHOPIK, ITS AFFILIATES, OR THEIR RESPECTIVE EMPLOYEES,
              CONTRACTORS, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR ANY
              INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
              EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR
              BUSINESS INTERRUPTION, LOSS OF PROFITS, GOODWILL, USE, DATA OR
              OTHER INTANGIBLE LOSSES ARISING OUT OF OR RELATING TO THE SITE;
              AND (b) IN NO EVENT SHALL SHOPIK'S CUMULATIVE AND AGGREGATE
              LIABILITY UNDER THESE TERMS EXCEED TWO HUNDRED U.S. DOLLARS. THE
              EXCLUSIONS AND LIMITATIONS IN THIS SECTION APPLY WHETHER THE
              ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
              LIABILITY OR ANY OTHER BASIS, EVEN IF YOU HAVE BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGE.
            </p>
            <p>‍</p>
            <p>12. Responsibility for End Users</p>
            <p>‍</p>
            <p>
              You are responsible for violations of these Terms by anyone using
              the Site with your permission or using your account on an
              unauthorized basis. Your use of the Site to assist another person
              in an activity that would violate these Terms if performed by you
              is a violation of these Terms. These Terms apply to anyone
              accessing or using the Site; however, each provision in these
              Terms shall be interpreted to include, and apply to, any action
              directly or indirectly taken, authorized, facilitated, promoted,
              encouraged, or permitted by a user of the Site, even if such
              person did not themselves violate the provision.
            </p>
            <p>‍</p>
            <p>13. Employment Opportunities</p>
            <p>‍</p>
            <p>
              Shopik may, from time to time, post employment opportunities on
              the Site and/or invite users to submit resumes to Shopik. If you
              choose to submit your name, contact information, resume, and/or
              other personal information to Shopik in response to such
              employment listings, you are authorizing Shopik to use this
              information for all lawful and legitimate hiring, employment, and
              other business purposes. Shopik also reserves the right, at its
              discretion, to forward such information to Shopik's affiliates for
              their legitimate business purposes. Nothing in these Terms or
              contained on the Site will constitute a promise by Shopik to
              review any such information or to contact, interview, or employ
              any individual who submits such information.
            </p>
            <p>‍</p>
            <p>14. Digital Millennium Copyright Act</p>
            <p>‍</p>
            <p>
              The Digital Millennium Copyright Act of 1998 (“DMCA”) provides
              recourse for copyright owners who believe that material appearing
              on the Internet infringes their rights under U.S. copyright law.
              If you believe that any material residing on or linked to from the
              Site infringes your copyright, please send (or have your agent
              send) to Shopik's Copyright Agent a notification of claimed
              infringement with all of the following information...
            </p>
            <p>‍</p>
            <p>
              To file a counter notification with Shopik, please provide the
              relevant information to Shopik's Copyright Agent. Shopik's
              Copyright Agent for notification of claimed infringement can be
              reached as follows: Copyright Agent, Shopik, Inc., Attn: Legal.
              Shopik's Copyright Agent for notification of claimed infringement
              can also be reached electronically at:{" "}
              <a href="mailto:hello@shopik.io" target="_new">
                hello@shopik.io
              </a>
              . Shopik reserves the right to terminate infringers' and suspected
              infringers' access to or use of the Site.
            </p>
            <p>‍</p>
            <p>15. U.S. Government Rights</p>
            <p>‍</p>
            <p>
              The Site is provided to the U.S. Government as “commercial items,”
              “commercial computer software,” “commercial computer software
              documentation,” and “technical data” with the same rights and
              restrictions generally applicable to the Site. If you are using
              the Site on behalf of the U.S. Government and these terms fail to
              meet the U.S. Government's needs or are inconsistent in any
              respect with federal law, you must immediately discontinue the use
              of the Site.
            </p>
            <p>‍</p>
            <p>16. Notice for California Residents</p>
            <p>‍</p>
            <p>
              Under California Civil Code Section 1789.3, California users are
              entitled to the following consumer rights notice: Shopik does not
              currently charge any fees for access and use of the Site. If you
              have a question or complaint regarding the Site, please contact
              Shopik by writing to Shopik, Inc., Attn: Legal, or by contacting
              Shopik at{" "}
              <a href="mailto:hello@shopik.io" target="_new">
                hello@shopik.io
              </a>
              . California residents may reach the Complaint Assistance Unit of
              the Division of Consumer Services of the California Department of
              Consumer Affairs by mail or by telephone.
            </p>
            <p>‍</p>
            <p>17. Termination and Monitoring</p>
            <p>‍</p>
            <p>
              If you violate these Terms, Shopik may suspend or terminate your
              use of the Site. Shopik's right to suspend or terminate your use
              of Site applies even if a breach is committed unintentionally or
              without your authorization. Shopik reserves the right, but does
              not assume the obligation, to investigate any violation of these
              Terms or misuse of the Site. Shopik may report any activity it
              suspects violates any law or regulation to appropriate law
              enforcement officials, regulators, or other appropriate third
              parties.
            </p>
            <p>‍</p>
            <p>18. Electronic Communications</p>
            <p>‍</p>
            <p>
              When you visit the Site or send emails to Shopik, you are
              communicating with Shopik electronically; and you consent to
              receive communications from Shopik electronically. You agree that
              all agreements, notices, disclosures, and other communications
              that Shopik provides to you electronically satisfy any legal
              requirement that such communications be in writing.
            </p>
            <p>
              ModificationsShopik may modify these Terms at any time by posting
              a revised version on the Site. By accessing the Site, you agree to
              the latest version of these Terms.
            </p>
            <p>
              Governing LawThis Agreement will be interpreted, construed, and
              enforced in all respects according to the relevant laws. Any legal
              action or proceeding arising under or relating to this Agreement
              shall be brought in the relevant courts, and the Parties expressly
              consent to personal jurisdiction and venue in those courts.
            </p>
            <p>
              MiscellaneousThese Terms constitute the complete and exclusive
              statement of the agreement between the Parties and supersede all
              proposals, oral or written, and all other communications between
              the Parties relating to the subject matter of these Terms.
            </p>
            <p>
              ContactPlease contact Shopik at{" "}
              <a href="mailto:hello@shopik.io" target="_new">
                hello@shopik.io
              </a>{" "}
              with any questions regarding these Terms.
            </p>
            <p>
              <br />
            </p>
            <p>‍</p>
            <p>‍</p>
            <h3>Privacy Policy</h3>
            <p>‍</p>
            <p>
              Your trust is essential to us, and we aim to ensure that your
              personal information is protected. This Privacy Policy explains
              how we collect and use your personal information in relation to
              Shopik products, services, events, and websites or applications
              that link to this Policy (together, the “Shopik Products”). It
              also describes your rights and how you can exercise them
              concerning your personal information.
            </p>
            <p>‍</p>
            <p>
              <strong>**Personal Information We Collect**</strong>
              <br />
              We process information about you while providing the Shopik
              Products. Below, we outline the types of information we collect.
            </p>
            <p>‍</p>
            <p>
              <strong>**Information You Give to Us**</strong>
              <br />- We collect information about you when you provide it to us
              directly.
              <br />
            </p>
            <p>‍</p>
            <p>
              <strong>**Your Shopik account.**</strong>
              <br />- We gather data about you when you submit it through the
              Shopik Products, including when you create an account with us.
              This may include your name, email address, profile picture,
              company name, postal address, and phone number. Additionally, it
              may encompass your payment information when you purchase any of
              the Shopik Products.
            </p>
            <p>‍</p>
            <p>
              <strong>**Support.**</strong>
              <br />- We collect information you provide to us when you request
              support or otherwise communicate with us (such as via third-party
              social-media sites). This may include your name, email address,
              title, company, and any other details you provide in your request.
            </p>
            <p>‍</p>
            <p>
              <strong>**Other information submitted by you.**</strong>
              <br />- We gather information about you that you submit to us
              through the Shopik Products, such as when you participate in any
              interactive features of the Shopik Products or complete a form on
              one of our websites. This data might include your name, email
              address, and mailing address.
            </p>
            <p>‍</p>
            <p>
              <strong>**Information We Collect Automatically**</strong>
            </p>
            <p>
              - We automatically gather data about you when you access or use
              the Shopik Products.
            </p>
            <p>‍</p>
            <p>
              <strong>**Your use of the Shopik Products.**</strong>
            </p>
            <p>
              - We collect information about your session, including date, time,
              duration, the pages you viewed, and the page you navigated from.
            </p>
            <p>‍</p>
            <p>
              <strong>**Device information.**</strong>
            </p>
            <p>
              - We gather details about your computer or device, such as the
              type of browser you use, your IP address, and your device's
              location.
            </p>
            <p>‍</p>
            <p>
              <strong>**Cookies and similar technologies.**</strong>‍
            </p>
            <p>
              - We utilize cookies and similar technologies (like web beacons
              and pixels) to collect data about your interactions with the
              Shopik Products. Please see our Cookie Policy for more details on
              how we use cookies and related technologies.
            </p>
            <p>‍</p>
            <p>
              <strong>**Information We Collect from Other Sources**</strong>
            </p>
            <p>
              - We may obtain additional details about you from other sources.
            </p>
            <p>‍</p>
            <p>
              <strong>**Other services linked to your account.**</strong>
            </p>
            <p>
              - We may gather data when you link your Shopik account with other
              services. For instance, if you use Google Apps credentials via
              single sign-on, we might access certain information like your name
              and email address, depending on your Google Apps profile settings.
            </p>
            <p>‍</p>
            <p>
              <strong>**Our affiliates.**</strong>
            </p>
            <p>
              - We may get data about you from one of our affiliates if you
              interact with them directly. This can include your name, email
              address, and request details.
            </p>
            <p>‍</p>
            <p>
              <strong>**Third parties.**</strong>
            </p>
            <p>
              - We may receive information about you from third parties that
              provide business information to us or from public sources. We may
              combine this with information we collect through other means
              mentioned above.
            </p>
            <p>‍</p>
            <p>
              <strong>**How We Use Personal Information**</strong>
            </p>
            <p>We use your information for various purposes:</p>
            <p>‍</p>
            <p>
              - To provide the Shopik Products.
              <br />- For research and improvement of Shopik Products.
              <br />- To communicate with you.
              <br />- For security.
              <br />- To market and promote Shopik Products.
              <br />- To comply with legal obligations.
              <br />- With your consent.
            </p>
            <p>‍</p>
            <p>
              <strong>**How We Share Personal Information**</strong>
            </p>
            <p>
              Your personal information might be shared in the following ways:
            </p>
            <p>‍</p>
            <p>
              - Service providers.
              <br />- For legal reasons.
              <br />- Business transfers.
              <br />- Affiliates.
              <br />- Marketing and analytics.
              <br />- Social Media.
              <br />- With your consent.
            </p>
            <p>‍</p>
            <p>
              <strong>**Security**</strong>
            </p>
            <p>
              Protecting your personal information is crucial to us. To ensure
              your data's security, we continually implement and maintain a
              variety of technical and organizational measures. View our
              Security page to learn more about our specific security controls.
            </p>
            <p>‍</p>
            <p>
              <strong>**Retention**</strong>
            </p>
            <p>
              We retain your personal information only as long as necessary and
              then delete or archive it unless legally required for another
              legitimate and lawful purpose.
            </p>
            <p>‍</p>
            <p>
              <strong>**Children**</strong>
            </p>
            <p>
              Shopik Products are not intended for individuals under the age of
              16, and we do not knowingly collect or sell the personal data of
              children under 16.
            </p>
            <p>‍</p>
            <p>
              <strong>**Your Choices**</strong>‍
            </p>
            <p>
              You have several choices concerning how Shopik collects and uses
              your personal information.
            </p>
            <p>‍</p>
            <p>
              <strong>
                **Supplemental Notice for the EEA, UK, and Switzerland**
              </strong>
            </p>
            <p>
              This section provides additional details about GDPR, UK GDPR, and
              related matters.
            </p>
            <p>‍</p>
            <p>
              <strong>**Supplemental Notice for the United States**</strong>
            </p>
            <p>
              This section provides more details about personal data under
              various U.S. state data-protection and privacy laws.
            </p>
            <p>‍</p>
            <p>
              <strong>**Changes to this Privacy Policy**</strong>
            </p>
            <p>
              We occasionally update this Privacy Policy. Check the date at the
              top to see the latest revision.
            </p>
            <p>‍</p>
            <p>
              <strong>**Contact Us**</strong>
            </p>
            <p>
              If you have queries or concerns about this Privacy Policy, please
              contact us at: Shopik, Inc., 11230 Sandcastle Drive Unit E,
              Woodbury, MN&nbsp;55129; or hello@shopik.io.
            </p>
            <p>‍</p>
            <p>Contact our EU representative at hello@shopik.io.</p>
            <p>‍</p>
            <p>‍</p>
            <p>‍</p>
            <h2>Cookie Policy</h2>
            <p>Current as of Oct, 9, 2023. </p>
            <p>‍</p>
            <p>
              This Cookie Policy (this “Policy”) describes how Shopik, Inc.
              (“Shopik,” “we,” “us,” or “our”) uses cookies and similar
              technologies (collectively, “Cookies”) on websites that link to
              this Policy, (collectively, the “Sites”), and how you can manage
              the cookie settings in your browser.
            </p>
            <p>‍</p>
            <p>
              What is a Cookie?
              <br />A cookie is a small piece of data that is stored on your
              device when you visit a website. You can think of cookies as
              providing a 'memory' for a website in order to make the website
              function or work more efficiently by recognizing users and their
              activity over time. For instance, a cookie may be used to record
              where you have paused a video so that you can resume playing it
              from the same spot later on. Some Cookies are required for
              technical reasons in order for the Sites to operate, and we refer
              to these as “essential” or “strictly necessary” Cookies. Other
              Cookies enable us to track and target the interests of our users
              to enhance the experience on our Sites.
            </p>
            <p>‍</p>
            <p>
              Cookies set by Shopik are called “first-party cookies”. Cookies
              set by parties other than Shopik are called “third-party cookies”.
              Third-party cookies enable third-party features or functionality
              to be provided on or through the Sites.
            </p>
            <p>‍</p>
            <p>
              How Shopik Uses Cookies
              <br />
              Shopik uses Cookies to help us understand the profile of our
              visitors, recognize when you are signed in to your account, and
              provide you with a better user experience. We may also use Cookies
              to analyze and track data, determine the popularity of certain
              content, deliver advertising and content targeted to your
              interests on our Sites, and better understand your online
              activity.
            </p>
            <p>‍</p>
            <p>
              Third-Party Use of Cookies
              <br />
              Some content or applications on the Sites are served by
              third-parties that may use Cookies to collect information about
              you when you use the Sites. The information they collect may be
              associated with your personal information or they may collect
              information about your online activities over time and across
              different websites and other online services.
            </p>
            <p>‍</p>
            <p>
              We do not control third-party cookies or how they may be used. If
              you have any questions about an advertisement or other targeted
              content, you should contact the responsible provider directly.
            </p>
            <p>‍</p>
            <p>
              What are your Choices Regarding Cookies?
              <br />
              Most web browsers are set to accept Cookies by default. You can
              configure your browser to remove or reject Cookies as outlined
              below. By clearing the browsing history of your browser, you can
              delete Cookies from all websites you have visited on your device.
              Please note that if you choose to remove or reject Cookies, this
              could affect the availability and functionality of some of our
              services.
            </p>
            <p>‍</p>
            <p>
              Where can you find more information about cookies?
              <br />
              If you want to learn more about cookies, or how to control,
              disable or delete them, please visit http://www.aboutcookies.org
              for detailed guidance.
            </p>
            <p>‍</p>
            <p>
              You can learn about opting out of third-party targeting cookies on
              the Digital Advertising Alliance's consumer choice page here, the
              Network Advertising Initiative's consumer choice page here or the
              European Interactive Digital Advertising Alliance consumer choice
              page here.
            </p>
            <p>‍</p>
            <p>
              Changes to this Policy
              <br />
              We may change this Policy from time to time. If we make changes,
              we will notify you by revising the date at the top of the Policy.
              We encourage you to review this Policy whenever you access the
              Sites or otherwise interact with us to stay informed about our use
              of Cookies.
            </p>
            <p>‍</p>
            <p>
              Where can I get further information?
              <br />
              If you have questions or concerns about this Cookie Policy, please
              contact us at hello@shopik.io.
            </p>
            <p>‍</p>
            <p>‍</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    </div>
  );
}