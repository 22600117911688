// Login.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './authContext';
import { auth, db  } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, OAuthProvider } from 'firebase/auth';
import { useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, getDoc, setDoc, collection } from 'firebase/firestore';

const CustomerSelect = () => {
  const { user, shopikUser, customer, loading } = useAuth();
  const navigate = useNavigate();
// Login.js
 useEffect(() => {
  if (!loading && customer && Object.keys(customer).length > 0) {
    // Redirect to dashboard only if the customer is fully loaded
    navigate('/dashboard');
  } else if (!loading && shopikUser && Object.keys(shopikUser).length > 0) {
    // Redirect to dashboard only if the customer is fully loaded
    // navigate('/customer-select');
  }
  }, [loading, user, customer, navigate]);

  return (
    <div className='login-text'> 
       <img src="images/logo.png" loading="lazy" alt="" style={{ maxWidth: '300px' }} />
       <br/>
        <b>You are a member of other Shopik accounts.<br/> Contact them if you would like to access their features.</b>
        <br/>
        <div style={{ maxWidth: '300px'}} >
        <p>Trying to create your own Shopik account? </p><br/>
        </div>
        <a href="/tryitnow" className="main-button w-button">Sign Up</a>
    </div>
  );
};

export default CustomerSelect;