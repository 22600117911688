// import ScriptTag from 'react-script-tag';


export default function NotFound() {
  return (
    <div className="NotFound">
      <div class="utility-page-wrap">
      <div class="utility-page-content">
        <h1>404</h1>
        <h2>Page Not Found</h2>
        <div>The page you are looking for doesn&#x27;t exist or has been moved</div>
      </div>
      </div>
      </div>
  );
}