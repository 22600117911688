import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { useNavigate } from 'react-router-dom';
import { arrayMoveImmutable } from 'array-move';
import { useAuth } from './authContext';
import { auth } from '../firebase.js';
import { submitPhoto, db, appHandoff } from '../firebase.js';
import { formatCurrentTime, encodeFileAsBase64, convertHEICToJPEG, generateUUID } from './sharedUtils.js';
import ProductEditGeneric from './productEditGeneric.js'; // ProductEdit component is included here
import EXIF from 'exif-js';
import {
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  TextField,
  CircularProgress,
  IconButton,
  Select,
  MenuItem,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import '../shopik.webapp.bulkuploader.css'; // Retain custom CSS for additional styling

// Sortable element for each image
const SortableImage = sortableElement(({ image, groupIndex, imageIndex, groups, handleImageClick, handleRemoveImage, handleMoveImage, insertNewGroup }) => (
<div className="image-container-wrapper">

  <Card sx={{ margin: 2, width: '100%' }}> {/* Use Material-UI Card */}
  <div className="image-container">
    <img src={image.preview} alt="preview" style={{ maxWidth: '100%', cursor: 'pointer' }} onClick={() => handleImageClick(groupIndex, imageIndex)} />
    <CardContent>
      <Button
        variant="outlined"
        color="error"
        onClick={() => {
          if (window.confirm("Are you sure you want to remove this image?")) {
            handleRemoveImage(image, groupIndex);
          }
        }}
        sx={{ marginTop: 1  , color: '#382c6f', borderColor: '#382c6f', borderWidth: 2, fontWeight: 'bold'}}
      >
        Remove Image
      </Button>

      {groups.length > 1 && (
        <>
          <Typography variant="body2" sx={{ marginTop: 1 }}>
            If your picture is in the wrong product, move it to the correct product number using the dropdown below
          </Typography>
          <Select
            value={groupIndex}
            onChange={(e) => handleMoveImage(image, groupIndex, parseInt(e.target.value))}
            sx={{ marginTop: 1, width: '100%' }}
          >
            {groups.map((_, index) => (
              <MenuItem key={index} value={index}>Product {index + 1}</MenuItem>
            ))}
          </Select>
        </>
      )}

      {imageIndex < groups[groupIndex].files.length - 1 && (
        <Button
          variant="outlined"        
          onClick={() => insertNewGroup(groupIndex, imageIndex)}
          sx={{ marginTop: 1 , color: '#4751ed', borderColor: '#4751ed', borderWidth: 2, fontWeight: 'bold' }}
        >
          Split Group Here
        </Button>
      )}
    </CardContent>

  </div>
  </Card>
  </div>
));

// Sortable container for the list of images
const SortableImageList = sortableContainer(({ items, groupIndex, groups, handleImageClick, handleRemoveImage, handleMoveImage, insertNewGroup }) => {
  return (
    <div className='images' style={{ width: '100%' }}>
      {items.map((image, index) => (
        <SortableImage 
          key={`item-${index}`} 
          index={index} 
          image={image} 
          groupIndex={groupIndex} 
          imageIndex={index} 
          groups={groups} 
          handleImageClick={handleImageClick} 
          handleRemoveImage={handleRemoveImage}
          handleMoveImage={handleMoveImage}
          insertNewGroup={insertNewGroup} 
        />
      ))}
    </div>
  );
});

function Uploader({ uploaderStyle }) {
  const { customer, user, shopikUser, logout } = useAuth();
  const [groups, setGroups] = useState([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [processingIndex, setProcessingIndex] = useState(-1);
  const [newPath, setNewPath] = useState('');
  const [defaultPhotoCount, setDefaultPhotoCount] = useState(uploaderStyle === 1 ? 4 : 10);
  const [defaultTime, setDefaultTime] = useState(60000);
  const [defaultText, setDefaultText] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    const currentPath = window.location.pathname;
    const pathParts = currentPath.split('/');
    const lastPart = pathParts[pathParts.length - 1];
    setNewPath(`/m/products/${lastPart}`);
  }, []);

  let dateString = formatCurrentTime();

  let sku = (
    dateString.split('+')[0]
      .replace(/,/g, '')
      .replace(/:/g, '')
    + generateUUID().replace(/-/g, '').substring(0, 4)
  );

  const goToPlanPage = () => {
    navigate('/plans');
  };

  const handleDrop = async (acceptedFiles) => {
    const filesWithTimeAndGroup = await Promise.all(acceptedFiles.map(async (file, index) => {
      let convertedFile = file;
      if (file.type === 'image/heic' || file.type === 'image/heif') {
        const jpegBlob = await convertHEICToJPEG(file);
        if (jpegBlob) {
          convertedFile = new File([jpegBlob], file.name.replace(/\.[^/.]+$/, ".jpg"), { type: "image/jpeg" });
        }
      }
  
      let exifDate = null;
      if (file.type.startsWith('image/')) {
        try {
          const data = await new Promise((resolve) => {
            EXIF.getData(file, function () {
              resolve(EXIF.getAllTags(this));
            });
          });
          if (data && data.DateTimeOriginal) {
            exifDate = parseExifDate(data.DateTimeOriginal);
            if (!exifDate) {
              console.error(`Failed to parse EXIF date for: ${file.name}. Using lastModified date.`);
            }
          } 
        } catch (error) {
          console.error(`Error extracting EXIF data for: ${file.name}`, error);
        }
      }
  
      const finalTime = exifDate ? exifDate.getTime() : convertedFile.lastModified;
  
      // Check if the file has a name pattern like x-y (e.g., 1-1, 2-1, 2-2)
      const match = file.name.match(/^(\d+)-(\d+)/);
      const groupName = match ? match[1] : null; // Extract the group number if it exists
  
      return {
        file: convertedFile,
        time: finalTime,
        groupName: groupName,  // Include the group name if detected
        preview: URL.createObjectURL(convertedFile),
      };
    }));
  
    // Sort by time
    filesWithTimeAndGroup.sort((a, b) => a.time - b.time);
  
    const newGroups = [];
    let currentGroup = [];
    let photoCounter = 0;
  
    filesWithTimeAndGroup.forEach((file, index) => {
      if (file.groupName) {
        // Group by the name pattern (e.g., 1-1, 1-2 will be grouped together)
        if (currentGroup.length === 0 || file.groupName === currentGroup[0].groupName) {
          currentGroup.push(file);
          photoCounter++;
        } else {
          // Push the current group and start a new one
          newGroups.push({ files: currentGroup, text: `Group ${currentGroup[0].groupName}`, productDocument: '' });
          currentGroup = [file];
          photoCounter = 1;
        }
      } else {
        // Group by timestamp if no name pattern is detected
        if (currentGroup.length === 0 || 
            ((file.time - filesWithTimeAndGroup[index - 1].time < defaultTime) && photoCounter < defaultPhotoCount)) {
          currentGroup.push(file);
          photoCounter++;
        } else {
          newGroups.push({ files: currentGroup, text: defaultText, productDocument: '' });
          currentGroup = [file];
          photoCounter = 1;
        }
      }
    });
  
    if (currentGroup.length) {
      newGroups.push({ files: currentGroup, text: currentGroup[0].groupName ? `Group ${currentGroup[0].groupName}` : defaultText, productDocument: '' });
    }
  
    setGroups(prevGroups => [...prevGroups, ...newGroups]);
  };

  // Helper function to correctly parse EXIF date strings
  const parseExifDate = (exifDateStr) => {
    // EXIF date format: "YYYY:MM:DD HH:MM:SS"
    const exifRegex = /^(\d{4}):(\d{2}):(\d{2}) (\d{2}):(\d{2}):(\d{2})$/;
    const match = exifDateStr.match(exifRegex);
  
    if (match) {
      // Extract date parts and create a Date object
      const [ , year, month, day, hour, minute, second ] = match;
      return new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`);
    }
  
    return null; // Return null if parsing failed
  };

  const handleMoveImage = (image, fromGroupIndex, toGroupIndex) => {
    const newGroups = groups.map(group => ({ ...group, files: [...group.files] }));
    newGroups[fromGroupIndex].files = newGroups[fromGroupIndex].files.filter(img => img !== image);
    newGroups[toGroupIndex].files.push(image);
    const filteredGroups = newGroups.filter(group => group.files.length > 0);
    setGroups(filteredGroups);
  };

  const handleRemoveImage = (image, fromGroupIndex) => {
    const newGroups = groups.map(group => ({ ...group, files: [...group.files] }));
    newGroups[fromGroupIndex].files = newGroups[fromGroupIndex].files.filter(img => img !== image);
    const filteredGroups = newGroups.filter(group => group.files.length > 0);
    setGroups(filteredGroups);
  };

  const handleRemoveGroup = (groupIndex) => {
    if (window.confirm("Are you sure you want to remove this group?")) {
      const newGroups = groups.filter((_, index) => index !== groupIndex);
      setGroups(newGroups);
    }
  };

  const handleTextChange = (groupIndex, text) => {
    const newGroups = groups.map((group, index) =>
      index === groupIndex ? { ...group, text } : group
    );
    setGroups(newGroups);
  };

  const handleImageClick = (groupIndex, imageIndex) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = async (e) => {
      const file = e.target.files[0];
      let convertedFile = file;
      if (file.type === 'image/heic' || file.type === 'image/heif') {
        const jpegBlob = await convertHEICToJPEG(file);
        if (jpegBlob) {
          convertedFile = new File([jpegBlob], file.name.replace(/\.[^/.]+$/, ".jpg"), { type: "image/jpeg" });
        }
      }
      const newImage = {
        file: convertedFile,
        time: convertedFile.lastModified,
        preview: URL.createObjectURL(convertedFile),
      };
      const newGroups = groups.map((group, index) => {
        if (index === groupIndex) {
          const newFiles = [...group.files];
          newFiles[imageIndex] = newImage;
          return { ...group, files: newFiles };
        }
        return group;
      });
      setGroups(newGroups);
    };
    input.click();
  };
  
  const handleSubmitGroup = async (groupIndex) => {
    setGroups(prevGroups => {
      const newGroups = [...prevGroups];
      newGroups[groupIndex].isLoading = true;
      return newGroups;
    });
    try {
      const group = groups[groupIndex];
      const uploads = group.files;
      let productDocument = '';
      setProcessingIndex(groupIndex);
      const accumulatedResults = [];

      for (let i = 0; i < uploads.length; i++) {
        const upload = uploads[i];
        const base64Image = await encodeFileAsBase64(upload.file);
        const result = await submitPhoto({
          input: base64Image,
          speechInput: group.text,
          userId: auth.currentUser.uid,
          customerId:  customer.customerId,
          productDocument: productDocument,
          currentImageCount: i,
        });

        accumulatedResults.push(result);

        const resultData = result['data'];
        productDocument = resultData.productDoc;
        const dict = {
          'imageURL': resultData.imageURL,
          'photoIndex': i,
          'expectedImageCount': uploads.length,
          'productDocument': resultData['productDoc'],
          'productImageDocument': resultData['imageDoc'],
          'customerId': customer.id,
          'userId': shopikUser.userId,
          'sku': sku,
          'appVersion': '2.7'
        };

        if ((uploaderStyle === 1) && (user.instagram)) {
          dict['additionalShopifyTags'] = '@' + user.instagram;
        }

        appHandoff(dict).then((innerData) => {
          console.log('demo handed off to app ' + innerData);
        });
      }

      setGroups(prevGroups => {
        const newGroups = prevGroups.map((group, index) =>
          index === groupIndex ? { ...group, productDocument, isLoading: false } : group
        );
        return newGroups;
      });

      setProcessingIndex(-1); // Reset processing index after submission
    } catch (error) {
      console.error('Error processing files:', error);
      setGroups(prevGroups => {
        const newGroups = [...prevGroups];
        newGroups[groupIndex].isLoading = false;
        return newGroups;
      });
      setProcessingIndex(-1); // Reset processing index on error
    }
  };

  const handleSubmitAll = async () => {
    setHasSubmitted(true);
    for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
      if (!groups[groupIndex].productDocument) {
        await handleSubmitGroup(groupIndex);
      }
    }
  };

  const onSortStart = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling
  };

  const onSortEnd = ({ oldIndex, newIndex, groupIndex }) => {
    const newGroups = groups.map((group, index) => ({
      ...group,
      files: arrayMoveImmutable(group.files, oldIndex, newIndex),
    }));
    setGroups(newGroups);
  };

  const updateState = (groupIndex) => {
    // Use setGroups with prevGroups to ensure you are modifying the current state
    setGroups(prevGroups => {
      // Create a copy of the previous groups to avoid directly mutating state
      const newGroups = [...prevGroups];
      
      // Set the isProcessed property to true for the group at the given index
      newGroups[groupIndex] = {
        ...newGroups[groupIndex], // Ensure the rest of the group's properties remain unchanged
        isProcessed: true,        // Update the isProcessed property
      };
      
      // Return the new groups array to update the state
      return newGroups;
    });
  };

  const insertNewGroup = (groupIndex, imageIndex) => {
    const beforeSplit = groups[groupIndex].files.slice(0, imageIndex + 1);
    const afterSplit = groups[groupIndex].files.slice(imageIndex + 1);
    const newGroups = [...groups];
    
    const newGroup = { files: afterSplit, text: '', productDocument: '' };
    
    newGroups.splice(groupIndex + 1, 0, newGroup);
    
    newGroups[groupIndex] = { ...newGroups[groupIndex], files: beforeSplit };

    const filteredGroups = newGroups.filter(group => group.files.length > 0);

    setGroups(filteredGroups);
  };


  return (
    <Container maxWidth="lg">
      {(customer.hasActiveSubscription == null  ||  customer.hasActiveSubscription ||  customer.overrideSubscription) ? (
      <>
      {(customer.uploaderStyle === 1 && groups.length === 0) && (
        <Typography variant="h5" align="center" gutterBottom>
          You’re in! Upload your product pics and let the AI do its thing!
        </Typography>
      )}

      {groups.length === 0 && uploaderStyle !== 1 && (
        <>
        <TextField
          label="Default max number of photos per group"
          type="number"
          value={defaultPhotoCount}
          onChange={(e) => setDefaultPhotoCount(parseInt(e.target.value))}
          margin="normal"
          fullWidth
        />

        <TextField
            label="Time between photo groups (seconds)"
            type="number"
            value={defaultTime/1000}
            onChange={(e) => setDefaultTime(parseInt(e.target.value) * 1000)}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Default text for all groups/products"
            value={defaultText}
            onChange={(e) => setDefaultText(e.target.value)}
            margin="normal"
            fullWidth
          />
        </>
      )}

      <div className="groups">
        {groups.map((group, groupIndex) => (
          <Card key={groupIndex} sx={{ marginBottom: 2 }}>
            <CardContent>
            {!group.productDocument && (
              <>
              <IconButton
                onClick={() => handleRemoveGroup(groupIndex)}
                sx={{ float: 'right' , color: '#382c6f'}}
              >
                <DeleteIcon />
              </IconButton>            
              <Typography variant="h6">Product {groupIndex + 1}</Typography>
              </>
            )}
              
              {/* Insert ProductEdit when productDocument is available */}
              {group.productDocument ? (

                <ProductEditGeneric
                  customerId={customer.id}
                  productId={group.productDocument}
                  updateState={(updatedData) => updateState(groupIndex, updatedData)}
                  uploaderStyle={uploaderStyle}
                  groupIndex={groupIndex}
                />
              ) : (
                <>
                  <SortableImageList
                    items={group.files}
                    groupIndex={groupIndex}
                    groups={groups}
                    handleImageClick={handleImageClick}
                    handleRemoveImage={handleRemoveImage}
                    handleMoveImage={handleMoveImage}
                    insertNewGroup={insertNewGroup}            
                    onSortStart={onSortStart}
                    axis="y"
                    lockAxis="y"
                    disableAutoScroll={true}
                    pressDelay={200}
                    onSortEnd={(sortEnd) => onSortEnd({ ...sortEnd, groupIndex })}
                  />
                  <TextField
                    label="Enter item description"
                    value={group.text}
                    onChange={(e) => handleTextChange(groupIndex, e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                  <Button
                    variant="contained"
                    onClick={() => handleSubmitGroup(groupIndex)}
                    disabled={group.isLoading}
                    sx={{ marginTop: 1 ,  backgroundColor: '#4751ed', '&:hover': { backgroundColor: '#0f0721' }   }}
                  >
                    {group.isLoading ? <CircularProgress size={24} /> : 'Submit Product'}
                  </Button>
                </>
              )}
            </CardContent>
          </Card>
        ))}
      </div>

      <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <div className="drop-area">
              <Typography variant="body1">
                Drag & drop files here to add more products, or click to select files
              </Typography>
            </div>
          </div>
        )}
      </Dropzone>

      {!hasSubmitted && groups.length > 1 && (
        <Button variant="contained" color="primary" onClick={handleSubmitAll} sx={{ marginTop: 2 ,  backgroundColor: '#4751ed', '&:hover': { backgroundColor: '#0f0721' } }}>
          Submit All Products
        </Button>
      )}
      </>
    ) : (
      <><br/>You need a plan to refresh your credits<br/>
             <Button
            variant="contained"
            sx={{
                backgroundColor: '#4751ed',
                color: 'white',
                textTransform: 'none',
                ':hover': {
                    backgroundColor: '#6133aa',
                },
                marginTop: 2,
            }}
            onClick={goToPlanPage}
        >
            See All Plans
        </Button>
            </>
    )}
    </Container>
    
  );
}

export default Uploader;