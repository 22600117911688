
export default function TopNav() {
  return (
    <div className="TopNav">
      <div data-w-id="7ca16a3a-ee14-25a7-293a-6e10c3898f51" data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
        <div className="section-padding navbar-padding">
          <div className="container-default">
            <div className="navbar-content-wrapper">
              <a href="/" aria-current="page" className="logo-link w-nav-brand w--current"><img src="images/Shopik-Main-Logo-2400x1800.png" srcSet="images/Shopik-Main-Logo-2400x1800-p-500.png 500w, images/Shopik-Main-Logo-2400x1800-p-800.png 800w, images/Shopik-Main-Logo-2400x1800-p-1080.png 1080w, images/Shopik-Main-Logo-2400x1800.png 2400w" width="122" sizes="(max-width: 479px) 60vw, 161.9965362548828px" alt="" className="image"  style={{ paddingLeft: 20 }} /></a>              
              <div className="navbar-right-col">
                  <a href="/login" className="small-button w-button">Sign In</a>
              </div>
              {/* <div className="navbar-right-col" hidden>
                <nav role="navigation" className="navbar-menu-wrapper w-nav-menu">
                  <ul role="list" className="navbar-menu-list">
                  <li className="navbar-list-item">
                    <a href="/#solution" className="nav-link w-nav-link">Solutions</a>
                  </li>
                  <li className="navbar-list-item">
                    <a href="/#testimonials" className="nav-link w-nav-link">API</a>
                  </li>
                  <li className="navbar-list-item">
                    <a href="/#features" className="nav-link w-nav-link">Features</a>
                  </li>
                  <div hidden>
                  <li className="navbar-list-item">
                    <a href="/#pricing" className="nav-link w-nav-link">Pricing</a>
                  </li>
                  </div>
                  <li className="navbar-list-item">
                    <a href="/#faq" className="nav-link w-nav-link">FAQ</a>
                  </li>
                  <li className="navbar-list-item">
                    <a href="https://shopik.io/marketplace" className="nav-link w-nav-link">Shopik Marketplace</a>
                  </li>
                  <li className="navbar-list-item-show-in-mbl">
                  <div hidden>
                    <a href="/login" className="small-button w-button">Sign In</a>
                  </div>
                  </li>
                  </ul>
                </nav>
                <div className="menu-bueger w-nav-button">
                  <div className="burger-line-1"></div>
                  <div className="burger-line-2"></div>
                  <div className="burger-line-3"></div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}