
export default function Licenses() {
  return (
    <div className="Licenses">
     <div className="info-page-wrapper">
  <div className="section-padding licensing">
    <div className="container-default">
      <div className="licensing-wrp">
        <h1>Licenses</h1>
        <div className="assets-text">
          See Terms and Conditions Page for Privacy Policy, <br />
          <br />
          All graphical assets in this template are licensed for personal and
          commercial use. If you'd like to use a specific asset, please check
          the license below
        </div>
       
        <div className="line white" />
        <div className="licensing-item">
          <div className="licensing-item-left">
            <h3 className="white-heading">
              Fonts
              <br />
            </h3>
          </div>
          <div className="licensing-item-right">
            <div>
              The fonts in this template are from Google Fonts. You are free to
              use this font for personal &amp; commercial use.
            </div>
            <div className="licensing-inner-grid">
              <div className="license-wrp">
                <a
                  href="https://fonts.google.com/specimen/Instrument+Sans?query=Instrument+Sans"
                  target="_blank"
                  className="license-link"
                >
                  Source &amp; License
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  );
}
