import heic2any from "heic2any";

// Function to format the current date to "YYYYMMDD,HH:MM:SS+0000"
export function formatCurrentTime() {
  const pad = (num, size) => {
    let s = num.toString();
    while (s.length < size) s = "0" + s;
    return s;
  };

  const date = new Date();
  const year = date.getUTCFullYear();
  const month = pad(date.getUTCMonth() + 1, 2);
  const day = pad(date.getUTCDate(), 2);
  const hours = pad(date.getUTCHours(), 2);
  const minutes = pad(date.getUTCMinutes(), 2);
  const seconds = pad(date.getUTCSeconds(), 2);

  return `${year}${month}${day},${hours}:${minutes}:${seconds}+0000`;
}
// Function to parse a formatted date string and convert it to local time
export function formattedDate(dateString) {
  if (typeof dateString !== 'string') {
    throw new Error("Invalid input, expected a string in the format 'YYYYMMDD,HH:MM:SS+0000'");
  }

  const parseDate = (dateStr) => {
    const year = dateStr.slice(0, 4);
    const month = dateStr.slice(4, 6);
    const day = dateStr.slice(6, 8);
    const time = dateStr.slice(9, 17).split(':');

    // Ensure the date is valid
    if (!year || !month || !day || time.length !== 3) {
      throw new Error("Invalid date string format. Expected 'YYYYMMDD,HH:MM:SS+0000'");
    }

    return new Date(Date.UTC(year, month - 1, day, time[0], time[1], time[2]));
  };

  try {
    const date = parseDate(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date constructed from the string.");
    }

    // Format the date to user's local timezone
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };

    return date.toLocaleString(undefined, options);
  } catch (error) {
    console.error("Error formatting date:", error.message);
    return "Invalid date";
  }
}
// Function to encode a file as a base64 string
export const encodeFileAsBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result.split(',')[1]);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

// Function to convert HEIC to JPEG
export const convertHEICToJPEG = (blob) => {
  return heic2any({
    blob,
    toType: "image/jpeg",
  }).then((conversionResult) => {
    return conversionResult;
  }).catch((error) => {
    console.error(error);
    return null; // Handle errors as needed
  });
};

// Function to generate a UUID
export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const customerHasShopify = (customer) => {
  if (!customer.destinations) {
    return false
  }
  return customer.destinations.filter(destination => destination.type === 1).length > 0;
}

export const customerHasEbay = (customer) => {
  if (!customer.destinations) {
    return false
  }
  return customer.destinations.filter(destination => destination.type === 2).length > 0;
}