import { db } from './firebase.js'
import { query, collection, collectionGroup, orderBy, getDocs, limit, startAfter, startAt, doc , where, or, and} from "firebase/firestore";

export const 
getProducts = (passedLimit, lastSnap, customer, searchString, completion, override=false) => { 
    try {
      const productsQuery = customer ? (searchString ? searchCustomerProducts(passedLimit, lastSnap, customer, searchString.toLowerCase(), override) : fetchCustomerProducts(passedLimit, customer, lastSnap, override)) : (searchString ? searchProducts(passedLimit, lastSnap, searchString.toLowerCase(), override) : fetchProducts(passedLimit, lastSnap, override));
      
      getDocs(productsQuery).then((querySnapshot) => {

        const productList = [];
        const currentId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

        querySnapshot.forEach((doc) => {
          const tempData = doc.data();
          const results = tempData.editedResults ? tempData.editedResults : tempData.chatGptResponse;
          results['noBgImageUrls'] = tempData.noBgImageUrls;
          results['thumbnailUrls'] = [];
          results['productUri'] = doc.id;
          if (tempData.isFree) {
            results['price'] = 'FREE'
          } else if (typeof results.price === 'string' || results.price instanceof String) {
              results['price'] = '$' + results.price.replace('$', '')
          } else {
            results['price'] = 'Unknown'
          }
          if (tempData.mainImages) {
            tempData.mainImages.forEach((image) => {
              if (image.isHidden == false) {
                results['thumbnailUrls'].push(image.urlString)
              }
            })
            if (results['thumbnailUrls'].length > 0 ) {
              results['mainImage'] = results['thumbnailUrls'][0];
            } 
          } else {
            if (tempData.noBgImageUrls.length > 0 ) {
              results['mainImage'] = tempData.noBgImageUrls[0];
            } 
            results['thumbnailUrls'] = tempData.thumbnailUrls;
          }
          results.created = tempData.created
          results.onEbay = tempData.eBayArray ? true : false
          results.onShopify = tempData.shopifyArray ? true : false

          if (tempData.consolidatedData) {
            if (tempData.consolidatedData['Title']) {
              results['title'] = tempData.consolidatedData['Title']
            }
            if (tempData.consolidatedData['Description']) {
              results['description'] = tempData.consolidatedData['Description']
            }
            if (tempData.consolidatedData['Price']) {
              results['price'] = '$' + tempData.consolidatedData['Price'].replace('$', '')
            }
          }
          if ((currentId !== doc.id) && (tempData.hideFromMarketplace == null || tempData.hideFromMarketplace == false || customer != null || override) && (tempData.customerId != 'siteDemo' || customer != null)){
            productList.push(results);
          }
        });
        const lastItem = querySnapshot.docs[querySnapshot.docs.length - 1];
        completion(productList, lastItem);
      });
    } catch (error) {
      console.log('Error getting documents: ', error);
    }
};

const fetchProducts = (passedLimit, lastSnap, override=false) => {
  const customerFilter =  or(where('customerId', '==', 'A77eZv8Ku1h5Ohn68cFarpjNrkk1'),
  where('customerId', '==', 'ntjs7Ybyd1XibATjUN3xVFCDQsa2'))
  const filter = (db.app.options.projectId == 'shopik-stage' || override) ? where('finishedProcessing', '==', true) :  and(where('finishedProcessing', '==', true), where('hideFromMarketplace', '==', false), customerFilter)
  return lastSnap
    ? query(
        collectionGroup(db, 'products'),
        filter,
        orderBy('created', 'desc'),
        startAfter(lastSnap),
        limit(passedLimit)
      )
    : query(collectionGroup(db, 'products'), 
        filter,
        orderBy('created', 'desc'), 
        limit(passedLimit));
};

const fetchCustomerProducts = (passedLimit, customer, lastSnap, override=false) => {
  const custColRef = collection(db, "customers")
  const custDocRef = doc(custColRef, customer)
  const filter = where('finishedProcessing', '==', true)
  
  return lastSnap
    ? query(
        collection(custDocRef, 'products'),
        filter,
        orderBy('created', 'desc'),
        startAfter(lastSnap),
        limit(passedLimit)
      )
    : query(collection(custDocRef, 'products'),
        filter,
        orderBy('created', 'desc'), 
        limit(passedLimit));
};

const searchProducts = (passedLimit, lastSnap, searchString, override=false) => {
  const filter = (db.app.options.projectId == 'shopik-stage' || override) ? and(where('finishedProcessing', '==', true), where('searchableText', 'array-contains', searchString)) : and(where('finishedProcessing', '==', true), where('hideFromMarketplace', '==', false), where('searchableText', 'array-contains', searchString))
  return lastSnap
    ? query(
        collectionGroup(db, 'products'),
        filter,
        orderBy('created', 'desc'),
        startAfter(lastSnap),
        limit(passedLimit)
      )
    : query(collectionGroup(db, 'products'), 
        filter,
        orderBy('created', 'desc'), 
        limit(passedLimit));
};

const searchCustomerProducts = (passedLimit, lastSnap, customer, searchString, override=false) => {
  const custColRef = collection(db, "customers")
  const custDocRef = doc(custColRef, customer)

  return lastSnap
    ? query(
        collection(custDocRef, 'products'),
        where('finishedProcessing', '==', true),
        where('searchableText', 'array-contains', searchString),
        where('hideFromMarketplace', '==', false),
        orderBy('created', 'desc'),
        startAfter(lastSnap),
        limit(passedLimit)
      )
    : query(
        collection(custDocRef, 'products'),
        where('finishedProcessing', '==', true),
        where('hideFromMarketplace', '==', false),
        where('searchableText', 'array-contains', searchString),
        orderBy('created', 'desc'), 
        limit(passedLimit));
};