import React, { useRef, useEffect } from 'react';
import Hls from 'hls.js';

function HLSVideoPlayer() {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    let hls;
  
    // console.log("uuuu")
    // if (video) {
    //   if (Hls.isSupported()) {
    //     hls = new Hls();
    //     hls.loadSource('./video/output.m3u8');
    //     hls.attachMedia(video);
    //     video.muted = true;

    //     hls.on(Hls.Events.MEDIA_ATTACHED, () => {
    //       console.log('Media attached');
    //       video.play().catch(error => console.error('Error attempting to play', error));
    //     });

    //     hls.on(Hls.Events.MANIFEST_PARSED, () => {
    //       console.log('Manifest parsed, attempting to play...');
          
    //       video.play().catch(error => console.error('Error attempting to play', error));
    //       // video.addEventListener('canplay', () => {
    //       //   console.log('Canplay, attempting to play...');
    //       //   video.play().catch(error => {
    //       //     console.error('Play was interrupted:', error);
    //       //     // Optionally retry or handle error
    //       //   });
    //       // });
    //     });
    //   } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
    //     video.src = './video/output.m3u8';
    //     video.addEventListener('loadedmetadata', () => {
    //       video.play().catch(error => console.error('Error attempting to play', error));
    //     });
    //   }
    // }

    // Cleanup function to remove event listeners and destroy Hls instance when component unmounts
    return () => {
      if (hls) {
        hls.destroy();
      }
      if (video) {
        video.removeEventListener('canplay', handlePlay);
      }
    };
  }, []);

  // Helper function to handle play
  function handlePlay() {
    const video = videoRef.current;
    if (video) {
      video.play().catch(error => {
        console.error('Play was interrupted:', error);
        // Retry logic or error handling
      });
    }
  }

  return (
    <div>
      <video controls width="100%" height="auto" autoPlay muted preload="metadata"> 
        <source type="video/mp4" src="https://storage.googleapis.com/shopik-production.appspot.com/staticData/video/output.mp4" /> {/* Fallback source */}
        Your browser does not support this video format.
      </video>
    </div>
  );
}

export default HLSVideoPlayer;