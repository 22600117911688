import Stripe from 'stripe';

// Initialize Stripe with your secret key (use a test key in development)
const stripe = new Stripe(process.env.REACT_APP_STRIPE_SECRET_KEY);

export const getOrCreateStripeCustomer = async (userId) => {
    try {
      
      // Check if a customer already exists in your backend or use a unique identifier for metadata
      const customers = await stripe.customers.list({
        limit: 1,
        // Use userId as a metadata filter if needed to find existing customers
        // metadata: { userId: userId }
      });
  
      // If a customer already exists with this userId, return it
      if (customers.data.length > 0) {
        return customers.data[0].id;
      }
  
      // Create a new customer in Stripe with userId as metadata
      const customer = await stripe.customers.create({
        metadata: {
          userId: userId, // Use userId as a unique identifier in metadata
        },
      });
  
      return customer.id;
    } catch (error) {
      console.error('Error creating or retrieving Stripe customer:', error);
      throw error;
    }
  };

// Function to update Stripe customer email
export const updateStripeCustomerEmail = async (customerId, email) => {
  try {
    // Update the customer with the new email address
    const customer = await stripe.customers.update(customerId, {
      email: email,
    });

    return customer;
  } catch (error) {
    console.error('Error updating Stripe customer email:', error);
    throw error;
  }
};