import axios from 'axios';

// Configuration for different environments
const baseURL = process.env.REACT_APP_API_URL

const PRODUCT_ENDPOINT = `${baseURL}product`;
const RETRY_ENDPOINT = `${baseURL}retry_product`;
const ACCOUNT_ENDPOINT = `${baseURL}account`;
const BULK_UPLOAD_ENDPOINT = `${baseURL}bulkupload`;
const PRICING_ENDPOINT = `${baseURL}pricing`;
const SALES_HISTORY_ENDPOINT = `${baseURL}sales_history`;
const IMAGES_ENDPOINT = `${baseURL}images_only`;

const createApiInstance = (apiKey) => {
    return axios.create({
        baseURL: baseURL,
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': `${apiKey}`, // User-specific API key
        }
    });
};

export const get = async (url, apiKey, params = {}) => {
    const api = createApiInstance(apiKey);
    try {
        const response = await api.get(url, { params });
        return response.data;
    } catch (error) {
        console.error(`GET ${url} failed:`, error);
        throw error; // Re-throw the error for the caller to handle
    }
};

export const post = async (url, apiKey, data) => {
    const api = createApiInstance(apiKey);
    try {
        const response = await api.post(url, data);
        return response.data;
    } catch (error) {
        console.error(`POST ${url} failed:`, error);
        throw error;
    }
};

export const put = async (url, apiKey, data) => {
    const api = createApiInstance(apiKey);
    try {
        const response = await api.put(url, data);
        return response.data;
    } catch (error) {
        console.error(`PUT ${url} failed:`, error);
        throw error;
    }
};

export const del = async (url, apiKey) => {
    const api = createApiInstance(apiKey);
    try {
        const response = await api.delete(url);
        return response.data;
    } catch (error) {
        console.error(`DELETE ${url} failed:`, error);
        throw error;
    }
};

export const postProductToChannel = async (apiKey, productId, channel, channelSettings, useOriginalImages = false) => {
    const api = createApiInstance(apiKey);
    const payload = { channel, channel_settings: channelSettings, use_original_images: useOriginalImages };

    try {
        const response = await api.post(`${PRODUCT_ENDPOINT}/${productId}`, payload);
        return response.data;
    } catch (error) {
        console.error(`Error posting product to channel: ${error.message}`);
        throw error; // Re-throw the error for the caller to handle
    }
};


export default createApiInstance;