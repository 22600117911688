
export const conditionKeys =  ['NEW', 'NEW_OTHER', 'NEW_WITH_DEFECTS','USED_EXCELLENT', 'USED_GOOD', 'USED_ACCEPTABLE', 'FOR_PARTS_OR_NOT_WORKING']
export const conditionValues =  ['New', 'New (Other)', 'New with Defects', 'Used - Excellent Condition', 'Used - Good Condition', 'Used - Acceptable Condition','For Parts or Not Working']
export const conditionToValueMap = {
    'NEW': 'New',
    'NEW_OTHER': 'New (Other)',
    'NEW_WITH_DEFECTS': 'New with Defects',
    'USED_EXCELLENT': 'Used - Excellent Condition',
    'USED_GOOD': 'Used - Good Condition',
    'USED_ACCEPTABLE': 'Used - Acceptable Condition',
    'FOR_PARTS_OR_NOT_WORKING': 'For Parts or Not Working'
}
// Other constants
export const constants = {
  EXCLUDE_FROM_PRODUCT_TERMS: ['unknown', 'not applicable', 'not provided', 'not available', 'not specified']
};


// Define the getDropdownOptions function
export const getDropdownOptions = (field, specs) => {
  if (field === 'Condition') {
    return conditionValues || [];
  }
  if (field === 'Category' || field === 'PrimaryCategory') {
    return specs.gptCategories.allPotentialCategories || []; 
  }

  // Default return for other fields
  return [];
};


// Define the getDropdownOptions function
export const translateDropdownOptions = (field, value) => {
  if (field === 'Condition') {
    return conditionToValueMap[value]
  }
  return value;
};
