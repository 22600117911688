const Team = () => {
  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      minHeight: '100vh', 
      textAlign: 'center', 
      backgroundColor: '#3b3b9c', 
      padding: '50px'
    }}>
      <div style={{ 
        position: 'relative', 
        maxWidth: '900px', 
        backgroundColor: '#ffffff', 
        borderRadius: '20px', 
        padding: '0px 30px 30px', 
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', 
      }}>
        
        {/* Team Title */}
        <h1 style={{ color: 'white', marginBottom: '0px' }}>&nbsp;</h1>
        
        {/* Profile Image Positioned Floating */}
        <div style={{ position: 'absolute', top: '-75px', left: '50%', transform: 'translateX(-50%)' }}>
          <img src="./images/graham.png" alt="Graham Wood" style={{ borderRadius: '50%', width: '150px', height: '150px', objectFit: 'cover'}} />
        </div>

        {/* Text Content */}
        <div style={{ textAlign: 'left' }}>
          <b style={{ fontSize: '24px', color: '#4751ed' }}>Graham Wood</b>, <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#4751ed' }}>Founder/CEO/CTO</span>
      
          <p style={{ fontSize: '16px', color: '#333', lineHeight: '1.6', marginTop: '10px' }}>
            Graham’s passion lies in creating software that automates routine tasks, empowering people to reclaim their time and focus on what truly matters.
            With a vision to enhance productivity through cutting-edge solutions, he is on a mission to design technologies that make tasks simpler and more efficient, allowing people to focus their time on more important tasks.
            <br /><br />
            Graham previously worked in engineering management at Google, had several successful startups, and was pivotal in the development of the Tasty app at BuzzFeed.
          </p>
    
        </div>
      </div>
    </div>
  );
};

export default Team;