import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { auth } from '../firebase.js';
import { submitPhoto, db, appHandoff } from '../firebase.js';
import '../shopik.webapp.bulkuploader.css';
import { formatCurrentTime, encodeFileAsBase64, convertHEICToJPEG, generateUUID } from './sharedUtils.js';
import ProductEdit from './productEdit.js';
import EXIF from 'exif-js'; 

// Sortable element for each image
const SortableImage = sortableElement(({ image, groupIndex, imageIndex, groups, handleImageClick, handleRemoveImage, handleMoveImage, insertNewGroup }) => (
  <div className="image-container-wrapper">
    <div className="image-container">
      <img src={image.preview} alt="preview" onClick={() => handleImageClick(groupIndex, imageIndex)} />
      <div className="split-button-container">
      <button className="custom-button-ingroup" onClick={() => {
        if (window.confirm("Are you sure you want to remove this image?")) {
          handleRemoveImage(image, groupIndex);
        }
      }}>Remove Image</button>
      </div>
      {groups.length > 1 && (
        <>If your picture is in the wrong product, move it to the correct product number using the dropdown below
        <select 
          value={groupIndex}
          onChange={(e) => handleMoveImage(image, groupIndex, parseInt(e.target.value))}
          className="split-button"
        >
          {groups.map((_, index) => (
            <option key={index} value={index}>Product {index + 1}</option>
          ))}
        </select>
        </>
      )}
      
      {imageIndex < groups[groupIndex].files.length - 1 && (
        <div className="split-button-container">
          <button className="custom-button-ingroup split-button" onClick={() => insertNewGroup(groupIndex, imageIndex)}>Split group here</button>
        </div>
      )}
    </div>
  </div>
));

// Sortable container for the list of images
const SortableImageList = sortableContainer(({ items, groupIndex, groups, handleImageClick, handleRemoveImage, handleMoveImage, insertNewGroup }) => {
  return (
    <div className="images">
      {items.map((image, index) => (
        <SortableImage 
          key={`item-${index}`} 
          index={index} 
          image={image} 
          groupIndex={groupIndex} 
          imageIndex={index} 
          groups={groups} 
          handleImageClick={handleImageClick} 
          handleRemoveImage={handleRemoveImage}
          handleMoveImage={handleMoveImage}
          insertNewGroup={insertNewGroup} 
        />
      ))}
    </div>
  );
});

function BulkUploader({ shopikUser, uploaderStyle }) {
  const [groups, setGroups] = useState([]);
  const params = useParams();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [processingIndex, setProcessingIndex] = useState(-1);
  const [newPath, setNewPath] = useState('');
  const [defaultPhotoCount, setDefaultPhotoCount] = useState(uploaderStyle === 1 ? 4 : 5);
  const [defaultText, setDefaultText] = useState('');

  useEffect(() => {
    const currentPath = window.location.pathname;
    const pathParts = currentPath.split('/');
    const lastPart = pathParts[pathParts.length - 1];
    setNewPath(`/m/products/${lastPart}`);
  }, []);

  let dateString = formatCurrentTime();

  let sku = (
    dateString.split('+')[0]
      .replace(/,/g, '')
      .replace(/:/g, '')
    + generateUUID().replace(/-/g, '').substring(0, 4)
  );

  const handleDrop = async (acceptedFiles) => {
    // console.log('handleDrop called with files:', acceptedFiles);
  
    const filesWithTime = await Promise.all(acceptedFiles.map(async (file, index) => {
      // console.log(`Processing file ${index + 1}/${acceptedFiles.length}: ${file.name}, type: ${file.type}`);
      
      let convertedFile = file;
      if (file.type === 'image/heic' || file.type === 'image/heif') {
        // console.log(`Converting HEIC/HEIF file: ${file.name}`);
        const jpegBlob = await convertHEICToJPEG(file);
        if (jpegBlob) {
          convertedFile = new File([jpegBlob], file.name.replace(/\.[^/.]+$/, ".jpg"), { type: "image/jpeg" });
          // console.log(`Converted to JPEG: ${convertedFile.name}`);
        } 
      }
    
      let exifDate = null;
      // Extract EXIF date if available.
      if (file.type.startsWith('image/')) {
        try {
          const data = await new Promise((resolve) => {
            EXIF.getData(file, function () {
              resolve(EXIF.getAllTags(this));
            });
          });
          if (data && data.DateTimeOriginal) {
            exifDate = parseExifDate(data.DateTimeOriginal);
            if (!exifDate) {
              console.error(`Failed to parse EXIF date for: ${file.name}. Using lastModified date.`);
            }
          } 
        } catch (error) {
          console.error(`Error extracting EXIF data for: ${file.name}`, error);
        }
      }
  
      const finalTime = exifDate ? exifDate.getTime() : convertedFile.lastModified;
      // console.log(`File ${file.name} - Final time used: ${new Date(finalTime)}`);
      return {
        file: convertedFile,
        time: finalTime,
        preview: URL.createObjectURL(convertedFile),
      };
    }));
  
    // console.log('Files after time extraction and conversion:', filesWithTime);
  
    filesWithTime.sort((a, b) => a.time - b.time);
    // console.log('Files sorted by time:', filesWithTime);
  
    const newGroups = [];
    let currentGroup = [];
    let photoCounter = 0;
  
    filesWithTime.forEach((file, index) => {
      // console.log(`Processing sorted file ${index + 1}/${filesWithTime.length}: ${file.file.name}`);
  
      // Check if we should start a new group based on time difference or photo count
      if (currentGroup.length === 0 || 
          ((file.time - filesWithTime[index - 1].time < 30000) && photoCounter < defaultPhotoCount)) {
        // If this file is close in time to the previous one and photo count is under limit, add to current group
        currentGroup.push(file);
        photoCounter++;
        
      } else {
        // If time difference is large or photo count reached, create a new group
        newGroups.push({ files: currentGroup, text: defaultText, productDocument: '' });
        currentGroup = [file]; // Start new group with current file
        photoCounter = 1; // Reset photo counter
      }
    });
  
    // Add the last group if it has any files
    if (currentGroup.length) {
      newGroups.push({ files: currentGroup, text: defaultText, productDocument: '' });
    }
  
    setGroups(prevGroups => [...prevGroups, ...newGroups]);
  };
  
  // Helper function to correctly parse EXIF date strings
  const parseExifDate = (exifDateStr) => {
    // EXIF date format: "YYYY:MM:DD HH:MM:SS"
    const exifRegex = /^(\d{4}):(\d{2}):(\d{2}) (\d{2}):(\d{2}):(\d{2})$/;
    const match = exifDateStr.match(exifRegex);
  
    if (match) {
      // Extract date parts and create a Date object
      const [ , year, month, day, hour, minute, second ] = match;
      return new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`);
    }
  
    return null; // Return null if parsing failed
  };

  const handleMoveImage = (image, fromGroupIndex, toGroupIndex) => {
    const newGroups = groups.map(group => ({ ...group, files: [...group.files] }));
    newGroups[fromGroupIndex].files = newGroups[fromGroupIndex].files.filter(img => img !== image);
    newGroups[toGroupIndex].files.push(image);
    const filteredGroups = newGroups.filter(group => group.files.length > 0);
    setGroups(filteredGroups);
  };

  const handleRemoveImage = (image, fromGroupIndex) => {
    const newGroups = groups.map(group => ({ ...group, files: [...group.files] }));
    newGroups[fromGroupIndex].files = newGroups[fromGroupIndex].files.filter(img => img !== image);
    const filteredGroups = newGroups.filter(group => group.files.length > 0);
    setGroups(filteredGroups);
  };

  const handleRemoveGroup = (groupIndex) => {
    if (window.confirm("Are you sure you want to remove this group?")) {
      const newGroups = groups.filter((_, index) => index !== groupIndex);
      setGroups(newGroups);
    }
  };

  const handleTextChange = (groupIndex, text) => {
    const newGroups = groups.map((group, index) =>
      index === groupIndex ? { ...group, text } : group
    );
    setGroups(newGroups);
  };

  const handleImageClick = (groupIndex, imageIndex) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = async (e) => {
      const file = e.target.files[0];
      let convertedFile = file;
      if (file.type === 'image/heic' || file.type === 'image/heif') {
        const jpegBlob = await convertHEICToJPEG(file);
        if (jpegBlob) {
          convertedFile = new File([jpegBlob], file.name.replace(/\.[^/.]+$/, ".jpg"), { type: "image/jpeg" });
        }
      }
      const newImage = {
        file: convertedFile,
        time: convertedFile.lastModified,
        preview: URL.createObjectURL(convertedFile),
      };
      const newGroups = groups.map((group, index) => {
        if (index === groupIndex) {
          const newFiles = [...group.files];
          newFiles[imageIndex] = newImage;
          return { ...group, files: newFiles };
        }
        return group;
      });
      setGroups(newGroups);
    };
    input.click();
  };

  const handleSubmitGroup = async (groupIndex) => {
    setGroups(prevGroups => {
      const newGroups = [...prevGroups];
      newGroups[groupIndex].isLoading = true;
      return newGroups;
    });
    try {
      const group = groups[groupIndex];
      const uploads = group.files;
      let productDocument = '';
      setProcessingIndex(groupIndex);
      const accumulatedResults = [];
  
      for (let i = 0; i < uploads.length; i++) {
        const upload = uploads[i];
        const base64Image = await encodeFileAsBase64(upload.file);
        const result = await submitPhoto({
          input: base64Image,
          speechInput: group.text,
          userId: auth.currentUser.uid,
          customerId: params['customer'],
          productDocument: productDocument,
          currentImageCount: i,
        });
  
        accumulatedResults.push(result);
  
        const resultData = result['data'];
        productDocument = resultData.productDoc;
        const dict = {
          'imageURL': resultData.imageURL,
          'photoIndex': i,
          'expectedImageCount': uploads.length,
          'productDocument': resultData['productDoc'],
          'productImageDocument': resultData['imageDoc'],
          'customerId': params['customer'],
          'userId': shopikUser.userId,
          'sku': sku,
          'appVersion': '2.7'
        };
  
        if ((uploaderStyle === 1) && (shopikUser.instagram)) {
          dict['additionalShopifyTags'] = '@' + shopikUser.instagram;
        }
  
        appHandoff(dict).then((innerData) => {
          console.log('demo handed off to app ' + innerData);
        });
      }
  
      setGroups(prevGroups => {
        const newGroups = prevGroups.map((group, index) =>
          index === groupIndex ? { ...group, productDocument, isLoading: false } : group
        );
        return newGroups;
      });
  
      setProcessingIndex(-1); // Reset processing index after submission
    } catch (error) {
      console.error('Error processing files:', error);
      setGroups(prevGroups => {
        const newGroups = [...prevGroups];
        newGroups[groupIndex].isLoading = false;
        return newGroups;
      });
      setProcessingIndex(-1); // Reset processing index on error
    }
  };
  
  const handleSubmitAll = async () => {
    setHasSubmitted(true); // Set hasSubmitted to true once before starting the submission
    for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
      if (!groups[groupIndex].productDocument) {
        await handleSubmitGroup(groupIndex);
      }
    }
  };

  const updateState = (groupIndex) => {
    // Use setGroups with prevGroups to ensure you are modifying the current state
    setGroups(prevGroups => {
      // Create a copy of the previous groups to avoid directly mutating state
      const newGroups = [...prevGroups];
      
      // Set the isProcessed property to true for the group at the given index
      newGroups[groupIndex] = {
        ...newGroups[groupIndex], // Ensure the rest of the group's properties remain unchanged
        isProcessed: true,        // Update the isProcessed property
      };
      
      // Return the new groups array to update the state
      return newGroups;
    });
  };

  const insertNewGroup = (groupIndex, imageIndex) => {
    const beforeSplit = groups[groupIndex].files.slice(0, imageIndex + 1);
    const afterSplit = groups[groupIndex].files.slice(imageIndex + 1);
    const newGroups = [...groups];
    
    const newGroup = { files: afterSplit, text: '', productDocument: '' };
    
    newGroups.splice(groupIndex + 1, 0, newGroup);
    
    newGroups[groupIndex] = { ...newGroups[groupIndex], files: beforeSplit };

    const filteredGroups = newGroups.filter(group => group.files.length > 0);

    setGroups(filteredGroups);
  };

  const combineWithNextGroup = (groupIndex) => {
    if (groupIndex < groups.length - 1) {
      const newGroups = groups.map(group => ({ ...group, files: [...group.files] }));
      newGroups[groupIndex].files = [...newGroups[groupIndex].files, ...newGroups[groupIndex + 1].files];
      newGroups.splice(groupIndex + 1, 1);
      setGroups(newGroups);
    }
    };
    const onSortStart = () => {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    };
    
    const onSortEnd = ({ oldIndex, newIndex, groupIndex }) => {
      document.body.style.overflow = '';
      const newGroups = groups.map((group, index) => ({
        ...group,
        files: [...group.files],
      }));
    
      newGroups[groupIndex].files = arrayMoveImmutable(newGroups[groupIndex].files, oldIndex, newIndex);
      setGroups(newGroups);
    };
    
    return (
      <div className="BulkUploader">
        {(uploaderStyle === 1) && (groups.length === 0) && (
          <div className='center-text'>
            <><b>You're in babe!<br/>Upload your product pics, and let the AI do its thing!</b>  <div className='example-image'></div></>
            {/* { isAnotherUpload && (<b>You did it! <br/>Upload more items, or if you're done, we will text you will your closet is ready to share! :)</b>)} */}
          </div>
        )}
        { (groups.length === 0) && uploaderStyle !== 1 && (
          <>
            <div>
              <label>
                Default max number of photos per group/product: &nbsp;&nbsp;
                <input
                  type="number"
                  value={defaultPhotoCount}
                  onChange={(e) => setDefaultPhotoCount(parseInt(e.target.value))}
                  min="1"
                />
              </label>
            </div>
            <div>
              <label>
                Default text for all groups/products: &nbsp;&nbsp;
                <input
                  type="text"
                  value={defaultText}
                  onChange={(e) => setDefaultText(e.target.value)}
                />
              </label>
            </div>
          </>
        )}
        <div className="groups">
        {groups.map((group, groupIndex) => (
          <div key={groupIndex} className="group card">
            {group.productDocument ? ( group.isProcessed ? ( <> <b>Thanks for submitting your items!! Our team is reviewing your closet. <br/>We'll contact you with a link to share on your socials ASAP</b></>) : 
            (
              <div className="ProductEdit-container">
                <ProductEdit
                  customerId={params['customer']}
                  productId={group.productDocument}
                  updateState={(updatedData) => updateState(groupIndex, updatedData)}
                  uploaderStyle={uploaderStyle}
                  groupIndex={groupIndex}
                />
              </div>
            )) : (
              <>
                <button className="remove-group" onClick={() => handleRemoveGroup(groupIndex)}>&times;</button>
                <h3>Product {groupIndex + 1}</h3>
                <SortableImageList
                  items={group.files}
                  groupIndex={groupIndex}
                  onSortStart={onSortStart}
                  groups={groups} // Pass groups to SortableImageList
                  onSortEnd={(sortEnd) => onSortEnd({ ...sortEnd, groupIndex })}
                  handleImageClick={handleImageClick}
                  handleRemoveImage={handleRemoveImage}
                  handleMoveImage={handleMoveImage} // Pass handleMoveImage
                  insertNewGroup={insertNewGroup} // Pass insertNewGroup
                  axis="y" // Restrict dragging to the vertical axis
                  lockAxis="y" // Lock the dragging axis to prevent scrolling                  
                  disableAutoScroll={true} // Prevent auto-scrolling while dragging
                  pressDelay={200}
                />
                <textarea 
                  value={group.text}
                  onChange={(e) => handleTextChange(groupIndex, e.target.value)}
                  placeholder="Enter item description"
                  className='custom-multiline-text-input'                    
                />
                <div className="group-actions">
                  {/* {groupIndex < groups.length - 1 && (
                    <button className="custom-button-ingroup" onClick={() => combineWithNextGroup(groupIndex)}>Combine with next group</button>
                  )} */}
                  {!group.isLoading ? (
                    <button className="custom-button-ingroup" onClick={() => handleSubmitGroup(groupIndex)}>Submit Product</button>
                  ) : (
                    <div className="spinner"></div>
                  )}
                </div>                  
              </>
            )}
          </div>
        ))}
      </div>
        <Dropzone onDrop={handleDrop}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <div className="drop-area">
                <p>Drag & drop files here to add more products, or click to select files</p>
              </div>
            </div>
          )}
        </Dropzone>
        {!hasSubmitted && groups.length > 1 && (<button className="custom-button" onClick={handleSubmitAll}>Submit All Products</button>)}
        {/* {hasSubmitted && (processingIndex === groups.length) && (
          <>Your products have uploaded and are processing. Your results will show up <a href={newPath}>here</a> and in your mobile app when complete</>
        )} */}
      </div>
    );
  }
    export default BulkUploader;