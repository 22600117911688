import React, { useState, useEffect } from 'react';
import { get, post } from './api';
import { useAuth } from './authContext';
import { useNavigate } from 'react-router-dom';
import ApiKeyField from './apiKeyField';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Import your Firebase configuration
import { Paper, Typography, Grid, TextField, Checkbox, FormControlLabel, Button, Container } from '@mui/material';

const Settings = () => {
    const navigate = useNavigate();
    const { customer, shopikUser } = useAuth(); // Assuming 'customer' contains user data
    const [apiKey, setApiKey] = useState(''); // API key state
    const [dataChanged, setDataChanged] = useState(false);
    const [userData, setUserData] = useState({
        // email: '',
        name: ''
    });
    const [customerData, setCustomerData] = useState({
        // email: '',
        premiumBackgroundRemoval: false
    });
     // Initial state for the destinations array
    const [destinations, setDestinations] = useState([
    ]);

    // Handle change in any destination field
    const handleDestinationChange = (index, field, value) => {
        const updatedDestinations = destinations.map((destination, i) =>
        i === index ? { ...destination, [field]: value } : destination
        );
        setDestinations(updatedDestinations);
        setCustomerData((prevData) => ({
            ...prevData,
            destinations: updatedDestinations
        }));
        setDataChanged(true)
    };

    // Add new destination
    const addNewDestination = () => {
        setDestinations([
        ...destinations,
        {
            id: "",
            includeDescription: true,
            isEnabled: false,
            postToDraft: true,
            storeId: "",
            storeKey: "",
            type: 1,
        },
        ]);
        setDataChanged(true)
    }; 
    // Remove destination by index
    const removeDestination = (indexToRemove) => {
        setDestinations((prevDestinations) => 
        prevDestinations.filter((_, index) => index !== indexToRemove)
        );
        console.log(destinations)
        setDataChanged(true);
    };

    useEffect(() => {
        // Fetch user data and API key when component mounts
        if (customer) {
            setUserData({
                // email: user.email || '',
                name: shopikUser.name || ''
            });
            setCustomerData({
                premiumBackgroundRemoval: customer.premiumBackgroundRemoval || true
            })
            setDestinations(customer.destinations || [])
            setApiKey(customer.apiKey)
        }

    }, [customer]);
    
    // Update user data
    const handleUserDataChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        setDataChanged(true)
    };

    // Update user data
    const handleCustomerDataChange = (e) => {
        const { name, value } = e.target;
        setCustomerData((prevData) => ({
            ...prevData,
            premiumBackgroundRemoval: value
        }));
        setDataChanged(true)
    };

    // Save user data to Firebase
    const cancelSave = async () => {
        if (customer) {
            setUserData({
                // email: user.email || '',
                name: shopikUser.name || ''
            });
            setCustomerData({
                premiumBackgroundRemoval: customer.premiumBackgroundRemoval || true
            })
            setDestinations(customer.destinations || [])
            setApiKey(customer.apiKey)
            setDataChanged(false)
        }
    };

    // Save user data to Firebase
    const saveUserData = async () => {
        try {
            // console.log(customer.id + ":" + user.id + ":" + shopikUser)
            if (customer && customer.id) { // Ensure customer has an id
                const userDoc = doc(db, 'users', customer.id); // Reference to the user's document in Firestore
                await setDoc(userDoc, userData, { merge: true }); // Update the document, merging fields if it exists
                if (customer.customerId === shopikUser.userId) {
                    await saveCustomerData()
                }
                setDataChanged(false)
            } else {
                console.error('Customer ID not found. Cannot save user data.');
            }
        } catch (error) {
            console.error('Error updating user data:', error);
        }
    };
    
    // Save user data to Firebase
    const saveCustomerData = async () => {
        try {
            // console.log(customer.id + ":" + user.id + ":" + shopikUser)
            if (customer && customer.customerId) { // Ensure customer has an id
                const userDoc = doc(db, 'customers', customer.customerId); // Reference to the user's document in Firestore
                var newData = customerData
                newData.destinations = destinations
                await setDoc(userDoc, newData, { merge: true }); // Update the document, merging fields if it exists
            } else {
                console.error('Customer ID not found. Cannot save customer data.');
            }
        } catch (error) {
            console.error('Error updating customer data:', error);
        }
    };
    
    // Regenerate API Key
    const regenerateApiKey = async () => {
        try {
            const response = await post('/regenerate-api-key'); // Replace with actual API endpoint
            setApiKey(response.apiKey);
            alert('API key regenerated successfully!');
        } catch (error) {
            console.error('Error regenerating API key:', error);
        }
    };

    return (
        <Container>
            <Paper style={{ padding: 20, marginBottom: 20 }}>
                <Typography variant="h6" gutterBottom>
                    Personal Information
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Name"
                            variant="outlined"
                            fullWidth
                            name="name"
                            value={userData.name}
                            onChange={handleUserDataChange}
                        />
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            fullWidth
                            name="email"
                            value={userData.email}
                            onChange={handleUserDataChange}
                        />
                    </Grid> */}
                </Grid>                
            </Paper>
            
            {(customer.customerId === shopikUser.userId) && (   
            <>
            {/* {(customer.overrideSubscription === false) && (
               <Paper style={{ padding: 20, marginBottom: 20 }}>
                <Typography variant="h6" gutterBottom>
                    Listing settings
                </Typography>
                <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={customer.premiumBackgroundRemoval}
                            onChange={handleCustomerDataChange}
                        />
                        }
                        label="Premium background removal"
                    />
                </Grid>
                </Grid>                
            </Paper>     
            )} */}
            <Paper style={{ padding: 20, marginBottom: 20 }}>
                <Typography variant="h6" gutterBottom>
                    Integration Information
                </Typography>
                <>
                {destinations.map((destination, index) => (
                    <>
                    {( destination.type === 1 ) && (
                    <Paper key={index} style={{ padding: 20, marginBottom: 20 }}>
                    <Typography variant="h6" gutterBottom style={{ marginBottom: 20 }}>
                        Shopify Integration {destination.storeId}
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                        <TextField
                            label="Store ID (name or ID from Shopify (<storeId>.myshopify.com))"
                            variant="outlined"
                            fullWidth
                            value={destination.storeId}
                            onChange={(e) =>
                            handleDestinationChange(index, "storeId", e.target.value)
                            }
                        />
                        </Grid>
                        <Grid item xs={12} md={6}>
                        {/* <TextField
                            label="Store Key"
                            variant="outlined"
                            fullWidth
                            value={destination.storeKey}
                            onChange={(e) =>
                            handleDestinationChange(index, "storeKey", e.target.value)
                            }
                        /> */}
                        <ApiKeyField apiKey={destination.storeKey} label='Store Key' onChange={(e) =>
                            handleDestinationChange(index, "storeKey", e.target.value)} readOnly={false}></ApiKeyField>
                        </Grid>           
                        {/* <Grid item xs={12} md={6}>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={destination.includeDescription}
                                onChange={(e) =>
                                handleDestinationChange(
                                    index,
                                    "includeDescription",
                                    e.target.checked
                                )
                                }
                            />
                            }
                            label="Include Description"
                        />
                        </Grid> */}
                        <Grid item xs={12} md={6}>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={destination.isEnabled}
                                onChange={(e) =>
                                handleDestinationChange(index, "isEnabled", e.target.checked)
                                }
                            />
                            }
                            label="Auto post items (Only select if you don't plan on editing AI generated info at all)"
                        />
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={destination.postToDraft}
                                onChange={(e) =>
                                handleDestinationChange(index, "postToDraft", e.target.checked)
                                }
                            />
                            }
                            label="Post to Draft"
                        />
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <Button
                            variant="outlined"
                            sx={{
                                backgroundColor: 'white', // Set background to white
                                color: '#4751ed', // Text color as original background color
                                borderColor: '#4751ed', // Border color as the original background color
                                textTransform: 'none',
                                ':hover': {
                                backgroundColor: '#4751ed', // Set hover background color
                                color: 'white', // Set hover text color to white
                                borderColor: '#6133aa', // Border color on hover to match the new hover color
                                },
                            }}
                            onClick={() => removeDestination(index)}>
                            Remove this store
                        </Button>
                        </Grid>
                    </Grid>
                    </Paper>                    
                    )}                   
                </>                
                ))}
                <Button 
                 variant="contained"
                 sx={{
                     backgroundColor: '#4751ed', // Custom background color
                     color: 'white', // Text color
                     textTransform: 'none',
                     ':hover': {
                     backgroundColor: '#6133aa', // Hover color
                     },
                 }}
                onClick={addNewDestination}>
                    Add Shopify Integration
                </Button>
                </>
            </Paper>
            </>
            )}
            {(customer.apiKey && (
                <Paper style={{ padding: 20 }}>
                <Typography variant="h6" gutterBottom>
                    API Key
                </Typography>
                <ApiKeyField apiKey={apiKey} regenerateApiKey={regenerateApiKey} />
            </Paper>
            ))}
            { dataChanged && (
            <>
            <Button
                variant="outlined"
                sx={{
                    backgroundColor: 'white', // Set background to white
                    color: '#4751ed', // Text color as original background color
                    borderColor: '#4751ed', // Border color as the original background color
                    textTransform: 'none',
                    ':hover': {
                    backgroundColor: '#4751ed', // Set hover background color
                    color: 'white', // Set hover text color to white
                    borderColor: '#6133aa', // Border color on hover to match the new hover color
                    },
                }}
                style={{ marginTop: 20 }}
                onClick={cancelSave}
            >
            Cancel
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
                variant="contained"
                sx={{
                    backgroundColor: '#4751ed', // Custom background color
                    color: 'white', // Text color
                    textTransform: 'none',
                    ':hover': {
                    backgroundColor: '#6133aa', // Hover color
                    },
                }}
                style={{ marginTop: 20 }}
                onClick={saveUserData} // Save user data to Firebase
            >
            Save
            </Button>
            </>  
            )}
        </Container>
    );
};

export default Settings;