import React, { useState, useEffect } from 'react';
import { Container, Typography, Select, MenuItem, Grid, Paper, Button, CircularProgress } from '@mui/material';
import { get } from './api';
import { useAuth } from './authContext';
import { useNavigate } from 'react-router-dom';
import { formattedDate } from './sharedUtils';

const Dashboard = () => {
    // Helper function to format date as 'YYYYMM'
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero if needed
        return `${year}${month}`;
    };

    // Get the current month in 'YYYYMM' format
    const getCurrentMonthYear = () => {
        return formatDate(new Date());
    };

    const { customer, user, logout } = useAuth();
    const [month, setMonth] = useState(getCurrentMonthYear()); // Default to current month (YYYYMM)
    const [months, setMonths] = useState([]); // State to hold dynamically generated months
    const [usageData, setUsageData] = useState(null);
    const [loading, setLoading] = useState(true); // Loading state for customer data
    const navigate = useNavigate();

    // Helper function to parse customer.created date string
    const parseCustomerDate = (dateString) => {
        // Extract components from the format "20240918,21:04:36+0000"
        const [datePart, timePart] = dateString.split(',');
        const year = parseInt(datePart.slice(0, 4));
        const month = parseInt(datePart.slice(4, 6)) - 1; // Months are 0-indexed in JS Date
        const day = parseInt(datePart.slice(6, 8));

        const [time, offset] = timePart.split('+'); // Split time and offset
        const [hours, minutes, seconds] = time.split(':').map(Number);

        // Construct a Date object
        return new Date(Date.UTC(year, month, day, hours, minutes, seconds));
    };

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const goToPlanPage = () => {
        navigate('/plans');
    };

    // Generate an array of months dynamically based on the customer.created date
    const generateMonths = (startDate) => {
        const monthsArray = [];
        const currentDate = new Date(); // Save the original current date
        const start = parseCustomerDate(startDate); // Parse startDate into a Date object

        // Calculate the number of months difference between startDate and currentDate
        const numberOfMonths = (currentDate.getFullYear() - start.getFullYear()) * 12 + currentDate.getMonth() - start.getMonth() + 1;

        for (let i = 0; i < numberOfMonths; i++) {
            // Create a new date object for each iteration to avoid modifying the original
            const date = new Date(currentDate);
            date.setMonth(currentDate.getMonth() - i);

            monthsArray.push({
                label: date.toLocaleString('default', { month: 'long', year: 'numeric' }), // e.g., "September 2024"
                value: formatDate(date), // e.g., "202409"
            });
        }
        return monthsArray;
    };

    useEffect(() => {
        if (customer && customer.created) {
            // Generate months based on customer.created date
            const monthsList = generateMonths(customer.created);
            setMonths(monthsList);
            setMonth(monthsList[0]?.value || getCurrentMonthYear());
            // if (customer.apiKey) {
                setLoading(false); // Data is fully loaded
            // }
        }
    }, [customer]);

    useEffect(() => {
        // Fetch usage data when the component mounts or the month changes
        const fetchData = async () => {
            if (!customer?.apiKey) {
                return;
            }
            try {
                console.log(month)
                // Replace with your API endpoint
                const response = await get(`usage?month=${month}`, customer.apiKey); // Adjust API as needed
                setUsageData(response);
            } catch (error) {
                console.error('Error fetching usage data:', error);
            }
        };

        fetchData();
    }, [month, customer?.apiKey]);

    return (
        <>
            {loading ? (
                <Container
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <CircularProgress />
                </Container>
            ) : (
                <Container>
                    <Typography variant="h4" gutterBottom>
                        Usage
                    </Typography>

                    {/* Month Selector */}
                    <Select
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}
                        displayEmpty
                        fullWidth
                        variant="outlined"
                    >
                        {months.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>

                    {/* Usage Data */}
                    {usageData ? (
                        <Grid container spacing={3} style={{ marginTop: 20 }}>
                            <Grid item xs={12} md={6}>
                                <Paper style={{ padding: 20 }}>
                                    <Typography variant="h6">Processed Products</Typography>
                                    <Typography variant="h4">{usageData.totalProductCount}</Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Paper style={{ padding: 20 }}>
                                    <Typography variant="h6">Processed Images</Typography>
                                    <Typography variant="h4">{usageData.productImageCount}</Typography>
                                </Paper>
                            </Grid> 
                            {/* <Grid item xs={12} md={6}>
                                <Paper style={{ padding: 20 }}>
                                    <Typography variant="h6">Background Removed Images</Typography>
                                    <Typography variant="h4">{usageData.backgroundRemovalCount}</Typography>
                                </Paper>
                            </Grid> 
                            <Grid item xs={12} md={6}>
                                <Paper style={{ padding: 20 }}>
                                    <Typography variant="h6">Credits remaining</Typography>
                                    <Typography variant="h4">{customer.planCreditsRemaining > 0 ? customer.planCreditsRemaining : 0}</Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Paper style={{ padding: 20 }}>
                                    <Typography variant="h6">Credits used</Typography>
                                    <Typography variant="h4">{usageData.totalProductCount * 5 + usageData.backgroundRemovalCount}</Typography>
                                </Paper>
                            </Grid> */}
                            {/* Add more stats as needed */}
                        </Grid>
                    ) : (
                        <Typography variant="h6" style={{ marginTop: 20 }}>
                            No usage in the selected month.
                        </Typography>
                    )}
                    { (customer.hasActiveSubscription == null  ||  customer.hasActiveSubscription ||  customer.overrideSubscription) ? (<>
                    </>) : (
                        <><br/>You need a plan to refresh your credits<br/>
                         <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#4751ed',
                            color: 'white',
                            textTransform: 'none',
                            ':hover': {
                                backgroundColor: '#6133aa',
                            },
                            marginTop: 2,
                        }}
                        onClick={goToPlanPage}
                    >
                        See All Plans
                    </Button>
                        </>
                    )}
                    { (customer.basePlan && customer.basePlan.periodEndDate && !customer.overrideSubscription) && (<>
                        <Typography variant="h6" style={{ marginTop: 20 }}>
                        Your credits will renew on {formattedDate(customer.basePlan.periodEndDate)}
                        </Typography>
                    </>)}
                </Container>
            )}
        </>
    );
};

export default Dashboard;