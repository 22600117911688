import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase'; // Import initialized auth and db from firebase.js
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, getDoc, setDoc, collection } from 'firebase/firestore';
import { getOrCreateStripeCustomer } from './stripeCustomer'; // Import your Stripe helper function

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, loading, error] = useAuthState(auth);
  const [shopikUser, setShopikUser] = useState({});
  const [customer, setCustomer] = useState({});
  const [isUserFetched, setIsUserFetched] = useState(false);

  useEffect(() => {
    if (user && !isUserFetched) {
      handleUserLogin(user);
    }
  }, [user, isUserFetched]);

  const handleUserLogin = async (user) => {
    try {
      const signInMethod = user.providerData[0]?.providerId || 'unknown';
      let userId = user.uid;

      // Handle local development environment
      if (process.env.REACT_APP_IS_LOCAL === 'true') {
        userId = 'localDebugDelete' + userId;
      }

      const userRef = doc(collection(db, "users"), userId);
      const docSnap = await getDoc(userRef);

      if (docSnap.exists()) {
        let userData = docSnap.data();

        // Check if stripeCustomerId exists, if not create a new one using userId
        if (!userData.stripeCustomerId) {
          const stripeCustomerId = await getOrCreateStripeCustomer(userId);
          userData['stripeCustomerId'] = stripeCustomerId;
          await setDoc(userRef, userData, { merge: true });
        }

        setupCustomer(userId, userId, userData.stripeCustomerId);
        setShopikUser(userData);

      } else {
        // Create new user data if not exists
        const stripeCustomerId = await getOrCreateStripeCustomer(userId);
        const newUserData = {
          appVersion: '2.7',
          created: formatCurrentTime(),
          customerId: userId,
          userId: userId,
          name: user.displayName,
          lastOnline: formatCurrentTime(),
          signInMethod: signInMethod.replace('.com', ''), // Use the extracted sign-in method
          customers: [userId],
          stripeCustomerId: stripeCustomerId, // Store the Stripe customer ID
        };

        await setDoc(userRef, newUserData, { merge: true });
        setupCustomer(newUserData, userId, stripeCustomerId);
        setShopikUser(newUserData);
      }

      setIsUserFetched(true); // Prevent the loop
    } catch (error) {
      console.error('Error handling user login:', error);
    }
  };

  async function setupCustomer(fromUser, forCustomerId, stripeCustomerId) {
    const dbCustRef = doc(collection(db, 'customers'), forCustomerId);

    try {
      const dbCust = await getDoc(dbCustRef);
      let customer;

      if (!dbCust.exists()) {
        const destinations = [];

        const customerDestination = {
          id: 'shopikDefault',
          type: 0,
          storeId: forCustomerId,
          storeKey: '',
          includeDescription: true,
          postToDraft: false,
          isEnabled: false,
        };

        destinations.push(customerDestination);

        const planData = {
          lastPaymentDate: '',
          nextPaymentDate: '',
          totalProductsUploaded: 0,
          currentPeriodProducts: 0,
        };

        customer = {
          id: forCustomerId,
          customerId: forCustomerId,
          created: formatCurrentTime(),
          subdomain: forCustomerId,
          users: [fromUser],
          destinations: destinations,
          planData: planData,
          stripeCustomerId: stripeCustomerId, // Store the Stripe customer ID here
        };

        await setDoc(dbCustRef, customer, { merge: true });
      } else {
        customer = dbCust.data();
        if (!customer.stripeCustomerId && fromUser.userId === forCustomerId) {
          customer['stripeCustomerId'] = stripeCustomerId
          await setDoc(dbCustRef, customer, { merge: true });
        }
      }
      setCustomer(customer);
    } catch (error) {
      console.error('Error setting up customer:', error);
    }
  }

  const formatCurrentTime = () => {
    const pad = (num, size) => {
      let s = num.toString();
      while (s.length < size) s = "0" + s;
      return s;
    };

    const date = new Date();
    const year = date.getUTCFullYear();
    const month = pad(date.getUTCMonth() + 1, 2);
    const day = pad(date.getUTCDate(), 2);
    const hours = pad(date.getUTCHours(), 2);
    const minutes = pad(date.getUTCMinutes(), 2);
    const seconds = pad(date.getUTCSeconds(), 2);

    return `${year}${month}${day},${hours}:${minutes}:${seconds}+0000`;
  };

  const value = {
    user,
    loading,
    error,
    shopikUser,
    customer,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};