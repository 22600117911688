import { submitPhoto, db, appHandoff  } from '../firebase.js'
import { doc, collection, onSnapshot, getDoc } from "firebase/firestore";
import React from 'react';
import { useEffect, useState } from 'react';
import ProductDescriptionFields from './productDescriptionFields.js';
import heic2any from "heic2any";

var isStaging = (process.env.REACT_APP_PROJECT_ID == "shopik-stage");


function convertHEICToJPEG(blob) {
  return heic2any({
    blob,
    toType: "image/jpeg",
  })
  .then((conversionResult) => {
    return conversionResult;
  })
  .catch((error) => {
    console.error(error);
    return null; // Handle errors as needed
  });
}
const constants = {
  EXCLUDE_FROM_PRODUCT_TERMS: ['unknown', 'not applicable', 'not provided', 'not available', 'not specified'] 
};

function buildSpecs(map) {
  const addAfter = [];
  const newArray = [];
  for (const item in map) {
    var value = map[item]
    if (value == '') {
      value = 'Not Available'
    }
    if (!constants.EXCLUDE_FROM_PRODUCT_TERMS.includes(value.toLowerCase())) {
      newArray.push([item, value])
    } else {
      addAfter.push(item);
    }
  }

  for (const item of addAfter) {
    var value = map[item]
    if (value == '') {
      value = 'Not Available'
    }
    newArray.push([item, value])
  }
  return newArray;
}

const processPhoto = function(file, callback) {
  const reader = new FileReader();  
  console.log(file);
  reader.onload = function () {
    
    const img = new Image();
    img.onerror = function(event) {
      // Handle the error, possibly calling the callback with an error
      console.error("Error loading image", event);
      convertHEICToJPEG(file).then( (jpgUrl) => {
        processPhoto(jpgUrl, callback);
      });
    };
    img.src = reader.result;
    img.onload = function () {
      // Set the canvas size to reduce the image size
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const maxWidth = 2500; // Adjust the maximum width as needed
      const maxHeight = 2500; // Adjust the maximum height as needed
      let newWidth = img.width;
      let newHeight = img.height;

      if (img.width > maxWidth) {
          newWidth = maxWidth;
          newHeight = (img.height * maxWidth) / img.width;
      }

      if (newHeight > maxHeight) {
          newHeight = maxHeight;
          newWidth = (img.width * maxHeight) / img.height;
      }

      canvas.width = newWidth;
      canvas.height = newHeight;

      // Draw the image on the canvas with the new dimensions
      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      // Convert the canvas content back to a Blob
      canvas.toBlob(function (blob) {
          // Display the resized image in an img element
          const reader2 = new FileReader();

          reader2.onload = function (e) {
              callback(e.target.result, null); // Assuming the first param is the result and the second is an error
          }
          reader2.onerror = function(error) {
              // Handle FileReader error
              console.error("Error reading blob", error);
              callback(null, error);
          };
          reader2.readAsDataURL(blob);
      }, file.type);
    };
  };
  reader.onerror = function(error) {
      // Handle FileReader error
      console.error("Error reading file", error);
      callback(null, error);
  };
  reader.readAsDataURL(file);
};

function randomNumber(min, max) {
    return Math.random() * (max - min) + min;
}

function capitalize(item) {
  return item.charAt(0).toUpperCase() + item.slice(1)
}

const handleDragEnter = function(e) {
  // e.classList.remove('highlight');
  e.preventDefault();
}

const handleDragLeave = function(e) {
  // e.classList.add('highlight');
  e.preventDefault();
}

const handleDragOver = function(e) {
  e.preventDefault();
}

function Demo() {
  const [file, setFile] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
  const [processingPhoto, setProcessingPhoto] = useState(false);
  const [originalImageSrc, setOriginalImageSrc] = useState(null);
  const [noBackgroundUrl, setNoBackgroundUrl] = useState(null);
  const [result, setResult] = useState(null);
  const [specs, setSpecs] = useState(null);
  const [prices, setPrices] = useState(null);
  
  const [textFieldContent, setTextFieldContent] = useState("");

  const demoDict = [
    ['Title', 'Liz Claiborne Medium Turquoise Handbag'],
    ['Description', 'Elevate your accessory collection with this chic Liz Claiborne medium-sized handbag, perfect for any occasion. Crafted with a vibrant turquoise leather, this purse seamlessly blends functionality with style. Featuring a durable blue zipper, a sleek blue leather strap for comfortable shoulder wear, and ample space for your essentials, it\'s a must-have for the fashion-forward individual. Established in the realm of high-quality accessories, Liz Claiborne\'s attention to detail is evident in the craftsmanship of this piece.'],
    ['Price', '$11.99'],
    ['Shape', 'Standard Purse'],
    ['Closure', 'Zip'],
    ['Handle/Strap Color', 'Turquoise'],
    ['Occasion', 'Casual'],   
    ['Size', 'Medium'],   
    ['Hardware Color', 'Metal'],
    ['Fabric Type', 'Leather'],
    ['Accents', 'Strap'],
    ['Vintage', 'No'],
    ['Personalize', 'No'],
    ['Department', 'Women'],
    ['Exterior Color', 'Turquoise'],
    ['Style', ' Purse'],
    ['Features', ' Zipper'],
    ['Unit Quantity', '1'],
    ['Handmade', ' No'],
    ['Pattern', ' Solid'],
    ['Handle Style', ' Strap'],
    ['Lining Material', ' Fabric'],
    ['Exterior Material', ' Leather'],
    ['Brand', ' LIZ CLAIBORNE'],
    ['Customized', ' No'],
    ['Hardware Material', ' Metal'],
    ['Theme', ' Lifestyle'],
    ['Handle/Strap Material', ' Leather'],
    ['Product Line', ' LIZ CLAIBORNE']
  ];
  
  const readyToSubmit = function () {
    setProcessingPhoto(true);
    setHasSubmitted(true);

    submitPhoto({ input: originalImageSrc, speechInput: textFieldContent }).then((result) => {
      const resultData = result['data']
      const custColRef = collection(db, "customers")
      const custDocRef = doc(custColRef, "siteDemo")
      const prodColRef = collection(custDocRef, "products")
      const prodDocRef = doc(prodColRef, resultData['productDoc']);
    
      const unsub = onSnapshot(prodDocRef, async (productDocument) => {

        const data =  productDocument.data()
        if (data['finishedProcessing']) {
          const results = { 'title': data.chatGptResponse.title, 'description': data.chatGptResponse.description, 'price': data.chatGptResponse.price, 'noBackgroundUrl': data.noBgImageUrls[0] }
          setResult(results)
          if (data['consolidatedData']) {
            const specDict = buildSpecs(data['consolidatedData'])
            setSpecs(specDict)
            setProcessingPhoto(false);
          }
          if (data['priceOptions']) {
            const specDict = buildSpecs(data['priceOptions'])
            setPrices(specDict)
          }
        } else if (data['completedImages'] && (noBackgroundUrl == null))  {
          const prodImageColRef = collection(custDocRef, "productImages")
          const imageRef = doc(prodImageColRef, resultData['imageDoc']);
          const docSnap = await getDoc(imageRef);
          const imageUrl = docSnap.data()['noBackground']
          setNoBackgroundUrl(imageUrl)
        }
      });

      const dict = { 
      'imageURL': resultData['imageURL'],
      'photoIndex': 0,
      'expectedImageCount': 1,
      'productDocument': resultData['productDoc'],
      'productImageDocument':resultData['imageDoc'],
      'customerId': 'siteDemo',
      'appVersion': '2.7'
      }

      appHandoff(dict).then((innerData) => {
        console.log('demo handed off to app ' + innerData)
      });
    });
  }

  const openCamera = function() {
    const cameraInput = document.getElementById('camera-input');
    cameraInput.click();
    cameraInput.addEventListener('change', (e) => {
      const file = e.target.files[0];
  
      if (file && file.type.startsWith('image/')) {
        setFile(file);
      }
    });
  }
  
  const handleDrop = function(e) {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
  
      if (file && file.type.startsWith('image/')) {
          setFile(file);
      }
  }

  const openFileInput = function() {
    const fileInput = document.getElementById('file-input');
    fileInput.click()
    fileInput.addEventListener('change', (e) => {
        const file = e.target.files[0];
  
        if (file && file.type.startsWith('image/')) {            
            setFile(file);
        }
    });
  }
  
  const demoPhotoTapped = function() {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      setFile(xhr.response);
    };
    xhr.open('GET', 'images/demo/0.jpg');
    xhr.responseType = 'blob';
    xhr.send();
  }

  useEffect(() => {
    if (file == null) {
      return 
    } else if (isReadyToSubmit == false) {
      processPhoto(file, (result) => { 
        setIsReadyToSubmit(true)
        if (result == null) {
          setFile(null)
        }
        setOriginalImageSrc(result)
      });
    } 
  }, [file]);

  return (
    <div className="Demo">
     <div className="terms-section">
        <div className="section-padding hero">
          <div className="container-default">
          {!hasSubmitted && !file && (
            <div id="image-input-area"> 
              <div className="drop-area-desktop" onClick={openFileInput}>
                <div id="drop-area"  onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} onDragOver={handleDragOver} onDrop={handleDrop}>
                  <p style={{color: 'black'}}>Drag image file here or<br />click to upload a photo</p> 
                  <input type="file" id="file-input" accept="image/*" hidden /> 
                </div>
              </div>
              <div className="drop-area-mobile">
                <div id="upload-photo-button" className="main-button w-button upload-photo" style={{display: 'block'}} onClick={openFileInput}> 
                  Upload a photo
                </div>
                <br /><br />
                <div id="take-photo-button" className="main-button w-button take-photo" style={{display: 'block'}}  onClick={openCamera}>
                  Take a photo
                </div>
                <input type="file" id="camera-input" capture="environment" accept="image/*" hidden />
              </div>
              <br /><br />
              <div className="image-container">
                <textarea cols="40" rows="50" id="text-input" className="form-input-demo" value={textFieldContent} onChange={(e) => setTextFieldContent(e.target.value)} placeholder="Type in any hints to AI that you think would be helpful to list this item(What condition is it in? What price would you like to sell this for? What is the model? Memory? etc)" />
              </div>
              <br/><br/>
            </div>)}
            {file && !hasSubmitted && isReadyToSubmit && (
              <div>
              <div id="image-info">
                <div className="demo-image image-container">
                  <img id="uploaded-image" src={originalImageSrc} alt="Uploaded Image" width={300} style={{background: 'white', borderRadius: '2px'}} />
                </div>
              </div>
              <div className="image-container">
      
              </div>
              <br/><br/>
              <div className="image-container">
                <textarea cols="40" rows="50" id="text-input" className="form-input-demo" value={textFieldContent} onChange={(e) => setTextFieldContent(e.target.value)} placeholder="Type in any hints to AI that you think would be helpful to list this item(What condition is it in? What price would you like to sell this for? What is the model? Memory? etc)" />
              </div>
              <br/><br/>
              {file && (
              <div className="image-container">
                <a onClick={readyToSubmit}  className="main-button w-button">I'm ready, let's go!</a>
              </div>
              )}
              </div>
            )}
            {file && !hasSubmitted && !isReadyToSubmit && (
              <div>
              <div id="image-info">
              <div className="demo-image image-container">
                 <div className='spinner'></div> 
                 </div>
              </div>
              <div className="image-container">
      
              </div>
              <br/><br/>
              <div className="image-container">
                <textarea cols="40" rows="50" id="text-input" className="form-input-demo" value={textFieldContent} onChange={(e) => setTextFieldContent(e.target.value)} placeholder="Type in any hints to AI that you think would be helpful to list this item(What condition is it in? What price would you like to sell this for? What is the model? Memory? etc)" />
              </div>
              <br/><br/>
            
              </div>
            )}
            {noBackgroundUrl && (
              <div id="image-info">
                <div className="demo-image image-container">
                  <img id="uploaded-image" src={originalImageSrc} alt="Uploaded Image" width={300} style={{background: 'white', borderRadius: '2px'}} />
                  <img id="processed-image" src={noBackgroundUrl} alt="Processed Image" width={300} style={{background: 'white', borderRadius: '2px', float: 'right'}} />
                </div>
                <div id="item-info-demo">

                  {result && (
                  <>
                  <p><b>Title:</b> {result.title}</p><p><b>Description:</b> {result.description}</p><p><b>Price:</b> {result.price}</p>
                  <p><b>Other Prices Found:</b> 
                  {prices && (prices.map(([key, value], index) => (
                    <div key={index}> {/* Use index for key to avoid React key warning if keys are not unique */}
                      <strong>{capitalize(key)}:</strong> {value}
                    </div>
                  )))}
                  </p>
                  {specs && (
                    <><ProductDescriptionFields data={specs} /> <br/> <br/> <a href="./tryitnow" className="main-button w-button" style={{marginLeft: '20px'}}>Try Again!</a></>
                  )}                  
                  </>
                  )}
                </div>
              </div>
            )}
            {processingPhoto && ( 
              <div className="demo-image image-container">
                <div className='spinner'></div> 
                {!noBackgroundUrl && (
                  <>Analyzing image and removing background....</>
                )}
                 {noBackgroundUrl && !result && (
                  <>Determining item information and price....</>
                )}

                {!specs && result && (
                  <>Getting more detailed specifications....</>
                )}
              </div>
            )}
            {!file && !hasSubmitted && (
              <div id="image-info">
               <h4>Here is an example of what to expect:</h4> 
                <div className="demo-image image-container">
                  <img id="uploaded-image" src='images/demo/0.jpg' alt="Uploaded Image" width={300} style={{background: 'white', borderRadius: '2px'}} />
                  <img id="processed-image" src='https://storage.googleapis.com/shopik-production.appspot.com/customers/siteDemo/images/zHI20wLmT8UR516SBSCE/noBackground/image0.jpg' alt="Processed Image" width={300} style={{background: 'white', borderRadius: '2px', float: 'right'}} />
                </div>
                <div id="item-info-demo">     
                  <ProductDescriptionFields data={demoDict} /> 
                </div>
              </div>
            )}
 
          </div>
        </div></div>
    </div>
  );
}

export default Demo;
