import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import ProductEditGeneric from './productEditGeneric.js';

export default function Product() {
  const navigate = useNavigate();
  const params = useParams();
  const customerId = params['customer'];
  const productId = params['product'];

  const updateState = (groupIndex) => {
    // Implementation for updating state
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Button 
        variant="outlined" 
        onClick={() => navigate(-1)} // Go back to the previous page
        sx={{ marginBottom: 2 }}
      >
        Back
      </Button>
      <ProductEditGeneric
        customerId={customerId}
        productId={productId}
        updateState={(updatedData) => updateState(0, updatedData)}
        uploaderStyle={0}
        groupIndex={0}
      />
    </Box>
  );
}